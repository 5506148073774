import React, {Component} from 'react';
import './header.less'
import {Button, Drawer, Menu, Modal, Tabs, Dropdown, message, notification, Form, Input} from 'antd';
import {connect} from 'react-redux';
import Banner from './banner.png'
import PullDownBtn from './pullDownBtn.png'
import axios from "../../axios";
import {addTabs, addUserAction, changeTabs, clearTabs} from "../../redux/action";
import Content from "../Content";
import {MenuUnfoldOutlined, MenuFoldOutlined, HomeOutlined, EyeTwoTone, EyeInvisibleOutlined} from "@ant-design/icons"
import Constant from "../../utils/constant";
import {LOGOUT, USER_INFO, USER_UPDATE, USER_UPDATE_PASSWORD} from "../../axios/url";
import Home from "./home";
import {checkPassword} from "../../utils/dataDeal";

const SubMenu = Menu.SubMenu;
const Item = Menu.Item;
const TabPane = Tabs.TabPane;
const FormItem = Form.Item;

class Header extends Component {
    state = {
        collapsed: false,
        visible: false,

        username: "",
        roleList: [],
        actionList: []
    };

    form = React.createRef();

    componentDidMount() {
        this.getUserInfo();
    }

    //获取用户信息
    getUserInfo = () => {
        const {dispatch} = this.props;

        axios.ajax({
            url: USER_INFO,
            method: "get",
            data: {
                params: {
                    userCode: localStorage.getItem(Constant.NAMESPANCE_NAME) ? localStorage.getItem(Constant.NAMESPANCE_NAME) : ""
                }
            }
        }).then((res) => {
            // console.dir(res);
            // console.log((res.DATA.roleList[0] || {}).authInfoList || []);
            this.setState({
                userInfo: res.DATA.sysUserInfo || {},
                username: (res.DATA.sysUserInfo || {}).userName || '',
                identitySign: res.DATA.userType,//身份
                roleList: res.DATA.roleList || [],
                actionList: this.getParentAction(((res.DATA.roleList || [])[0] || {}).authInfoList || [])
            });

            dispatch(addUserAction(((res.DATA.roleList || [])[0] || {}).authInfoList || []));
        });
    };

    //显示角色列表
    setRoleList = () => {
        const {roleList} = this.state;
        // console.log(roleList);

        return (
            <Menu>
                <Menu.Item onClick={() => this.setState({showPsd: true})}>修改密码</Menu.Item>
                <SubMenu title="角色列表">
                    {roleList.map((value) => (
                        <Menu.Item key={value.roleCode}>
                            {value.roleName}
                        </Menu.Item>
                    ))}
                </SubMenu>
            </Menu>
        );
    };

    //修改密码
    updatePassword = () => {
        let data = this.form.current.getFieldsValue();
        // console.log(data);
        if (checkPassword(data)) {
            axios.PostAjax({
                url: USER_UPDATE_PASSWORD,
                data: {
                    params: {
                        password: data.newPassword,
                        userCode: this.state.userInfo.userCode,
                    }
                }
            }).then((res) => {
                message.success(res.DATA);
                this.form.current.resetFields();
                this.setState({
                    showPsd: false
                });
                this.getUserInfo();
            });
        }
    };

    logout = () => {
        Modal.confirm({
            title: "确认退出",
            onOk() {
                axios.PostAjax({
                    url: LOGOUT,
                    data: {
                        params: {}
                    }
                }).then((res) => {
                    window.location.href = "#login"
                });
            },
            onCancel() {

            }
        })
    };

    //图标控制Menu显隐
    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
            visible: !this.state.visible
        });
    };

    //权限转为树形
    getParentAction = (data) => {
        let array = {};
        let returnArray = [];
        data.forEach(i => {
            i['children'] = [];
            array[i.authCode] = i;
        });

        data.forEach(i => {
            // console.log(i);
            if (i.parentCode === "" || i.parentCode === null || i.parentCode === -1) {
                i.parentCode = -1;
                i.level = 1;
            }

            if (i.authClass === '0') {
                if (i.parentCode !== -1) {
                    array[i.parentCode].children.push(i);
                } else {
                    returnArray.push(i);
                }
            }
        });


        return returnArray;
    };

    //解析权限树
    renderTreeNodes = (data) => {
        // console.log("renderTreeNodes");
        // console.log(data);
        return data.map((item) => {
            if (item.children && (item.children || []).length !== 0) {
                return (
                    <SubMenu value={item.authCode} title={item.authName} key={item.authName}>
                        {this.renderTreeNodes(item.children)}
                    </SubMenu>
                );
            } else {
                return (
                    <Menu.Item value={item.authCode} title={item.authName} key={item.authName}>
                        {item.authName}
                    </Menu.Item>
                );
            }
        });
    };

    //MenuItem点击
    handleClick = (type, item) => {
        // console.log("item:\n", item);//注意console.log与console.dir，console.dir直接加值
        // console.log("props:\n", this.props);
        const {dispatch} = this.props;
        const panes = this.props.panes || [];
        if (type === "onSelect") {
            const activeKey = item.key;
            if (!panes.find(p => p.key === activeKey)) {
                panes.push({
                    title: item.item.props.title,//item里找
                    content: item.item.props.title,
                    key: activeKey
                });
            }
            dispatch(addTabs(panes, activeKey));
        } else {
            if (!panes.find(p => p.key === item)) {
                panes.push({
                    title: item,
                    content: item,
                    key: item
                });
            }
            dispatch(addTabs(panes, item));
        }
    };

    //Tabs切换面板的回调
    onChange = (activeKey) => {
        // console.log("activeKey:\n", activeKey);
        const {dispatch} = this.props;
        dispatch(changeTabs(activeKey));
    };

    //Tabs（新增）和删除页签的回调
    onEdit = (targetKey, action) => {
        // console.log("targetKey:\n", targetKey);
        // console.log("action:\n", action);//remove
        this[action](targetKey);
    };

    //Tabs删除行为
    remove = (targetKey) => {
        const {dispatch} = this.props;
        let activeKey = this.props.activeKey;
        let lastIndex;
        this.props.panes.forEach((pane, i) => {
            if (pane.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const panes = this.props.panes.filter(pane => pane.key !== targetKey);
        if (panes.length && activeKey === targetKey) {
            if (lastIndex >= 0) {
                activeKey = panes[lastIndex].key;
            } else {
                activeKey = panes[0].key;
            }
        }
        dispatch(addTabs(panes, activeKey));
    };

    render() {
        const formItemLayout = {labelCol: {span: 6}, wrapperCol: {span: 16}};

        return (
            <div className="header">
                <div className="header-top">
                    <div style={{float: "left", fontSize: '30px', color: '#FFFFFF', margin: "30px 0 0 10px"}}
                         onClick={this.toggleCollapsed}>
                        {this.state.collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                    </div>
                    <img src={Banner} alt=""/>
                    <span>{Constant.APPLICATION_NAME}</span>
                    <a className="header-logout"
                       onClick={() => this.logout()}>退出登录</a>
                    <Dropdown overlay={this.setRoleList} trigger={['click']} placement="bottomRight">
                        <a className="header-role" onClick={e => e.preventDefault()}>
                            <img src={PullDownBtn} alt=""/>
                        </a>
                    </Dropdown>
                    <span>欢迎，{this.state.username}</span>
                </div>

                <Drawer
                    width="200px"
                    title="导航栏"
                    placement="left"//抽屉的方向
                    closable={false}//是否显示右上角的关闭按钮
                    onClose={this.toggleCollapsed}
                    visible={this.state.visible}>
                    <Menu
                        selectedKeys={[this.props.activeKey]}//当前选中的菜单项 key 数组
                        onSelect={(item) => this.handleClick("onSelect", item)}
                        theme="dark"//主题颜色
                        mode="inline"//菜单类型，内嵌模式
                    >
                        {this.renderTreeNodes(this.state.actionList)}
                    </Menu>
                </Drawer>
                <div className="breadcrumb">
                    <Tabs
                        hideAdd//是否隐藏加号图标
                        onChange={this.onChange}//切换面板的回调
                        activeKey={this.props.activeKey}//当前激活 tab 面板的 key
                        type="editable-card"
                        onEdit={this.onEdit}//新增和删除页签的回调，在 type="editable-card" 时有效
                    >
                        {
                            (this.props.panes || []).map((pane) => {
                                return <TabPane
                                    tab={pane.title}//选项卡头显示文字
                                    key={pane.key}//对应 activeKey
                                    closable={pane.closable}>
                                    {pane.title === "首页" ?
                                        <Home userInfo={this.state.userInfo}
                                              dispatchActiveKey={(activeKey) => {
                                                  this.handleClick("", activeKey);
                                              }}/>
                                        : <Content name={pane.title}/>}
                                </TabPane>;
                            })
                        }
                    </Tabs>
                </div>
                <Modal title="修改密码"
                       width={450}
                       visible={this.state.showPsd}
                       onOk={this.updatePassword}
                       okText={"保存"}
                       destroyOnClose={true}//关闭后销毁
                       maskClosable={false}//点击蒙层是否允许关闭
                       keyboard={false}//是否支持键盘 esc 关闭
                       onCancel={() => {
                           this.form.current.resetFields();
                           this.setState({
                               showPsd: false
                           })
                       }}>
                    <Form ref={this.form} layout="horizontal"
                          initialValues={{oldPassword: '', newPassword: '', newPassword1: ''}}>
                        <p style={{
                            color: "red", margin: "-10px 0px 5px 5px", fontSize: "small"
                        }}>密码至少8位，包括数字、大小写字母和特殊字符三种及以上</p>
                        <FormItem label="原密码输入" {...formItemLayout} name='oldPassword'
                                  rules={[{required: true, message: '原密码不能为空'}]}>
                            <Input.Password placeholder="请输入原密码" allowClear
                                            iconRender={visible => (visible ? <EyeTwoTone/> :
                                                <EyeInvisibleOutlined/>)}/>
                        </FormItem>
                        <FormItem label="新密码输入" {...formItemLayout} name='newPassword'
                                  rules={[{required: true, message: '请输入新密码！'}, {
                                      pattern: /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{8,20}$/,
                                      message: '密码格式错误！'
                                  }]}>
                            <Input.Password placeholder="请输入新密码" allowClear
                                            iconRender={visible => (visible ? <EyeTwoTone/> :
                                                <EyeInvisibleOutlined/>)}/>
                        </FormItem>
                        <FormItem label="新密码确认" {...formItemLayout} name='newPassword1'
                                  rules={[{required: true, message: '请确认新密码！'}, {
                                      pattern: /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{8,20}$/,
                                      message: '密码格式错误！'
                                  }]}>
                            <Input.Password placeholder="请确认新密码" allowClear
                                            iconRender={visible => (visible ? <EyeTwoTone/> :
                                                <EyeInvisibleOutlined/>)}/>
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        );
    }
}

//把store中的数据映射到这个组件变成props
const mapStateToProps = (state) => {
    // console.log("mapStateToProps:\n", state);
    return {
        panes: state.panes,
        activeKey: state.activeKey
    };
};

export default connect(mapStateToProps)(Header);

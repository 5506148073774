import React, {Component} from 'react';
import {Button, Popconfirm, Upload, Modal, message} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import Constant from "../../utils/constant";
import {connect} from 'react-redux';

class BaseTool extends Component {

    constructor(props) {
        super(props);
        this.state = {
            actionDisabled: []
        };
    }

    componentDidMount() {
        this.getDisabled();
    }

    getFileType = (fileType, type) => {
        let info;
        switch (fileType) {
            case "excel":
                info = [];
                info.push(['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']);
                info.push([".xls", ".xlsx"]);
                // console.log(info);
                return type === "accept" ? "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : info;
            case "image":
                info = [];
                info.push(['image/jpeg', 'image/png']);
                info.push([".jpg", ".jpeg", ".png"]);
                // console.log(info);
                return type === "accept" ? "image/jpeg,image/png" : info;
            case "pdf":
                info = [];
                info.push(['application/pdf']);
                info.push([".pdf"]);
                // console.log(info);
                return type === "accept" ? "application/pdf" : info;
            case "word":
                info = [];
                info.push(['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']);
                info.push([".doc", ".docx"]);
                // console.log(info);
                return type === "accept" ? "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" : info;
            case "ppt":
                info = [];
                info.push(['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation']);
                info.push([".ppt", ".pptx"]);
                // console.log(info);
                return type === "accept" ? "application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation" : info;
            case "video":
                info = [];
                info.push(['audio/mp4', 'video/mp4']);
                info.push([".mp4"]);
                // console.log(info);
                return type === "accept" ? "audio/mp4,video/mp4" : info;
            case "sound":
                info = [];
                info.push(['audio/mpeg', 'audio/mp3']);
                info.push([".mp3"]);
                // console.log(info);
                return type === "accept" ? "audio/mpeg,audio/mp3" : info;
            case "zip":
                info = [];
                info.push(['application/zip', 'application/x-zip-compressed']);
                info.push([".zip"]);
                // console.log(info);
                return type === "accept" ? "application/zip,application/x-zip-compressed" : info;
        }
    };

    getDisabled = () => {
        // console.log(this.props);
        let {actions, activeKey} = this.props;

        let id = (actions.filter(item => item.authName === activeKey)[0] || {}).id;
        let actionDisabled = actions.filter(item => item.parentCode === id) || [];
        // console.log(actionDisabled);

        this.setState({
            actionDisabled
        })
    };

    initTool = () => {
        let {type, disabled, onClick, name, style, display, activeKey} = this.props;
        let {actionDisabled} = this.state;
        // console.log(this.props);
        // console.log(this.state);

        if (display) {
            style = {
                ...style,
                display: actionDisabled.filter(item => (name === "删除" ? item.authName.slice(0, 2) : item.authName) === name)
                    .length !== 0 ? "initial" : "none"
            };
        }

        if (type === "button") {//表格内button
            // console.log(this.props);
            // console.log(disabled);
            return <Button type="primary" size="small" shape="link" style={{marginLeft: 10, ...style}}
                           disabled={disabled} onClick={onClick}>
                {name}
            </Button>
        } else if (type === "headButton") {//head内button
            return <Button type="primary" style={{float: "right", marginRight: "20px", ...style}}
                           disabled={disabled} onClick={onClick}>
                {name}
            </Button>
        } else if (type === "deleteButton") {//删除button
            const {size, shape} = this.props;
            return <Popconfirm title="确认删除" onConfirm={onClick}>
                <Button type="primary" size={size} shape={shape || "default"} disabled={disabled}
                        style={{...style}}>
                    {name}
                </Button>
            </Popconfirm>
        } else if (type === "importButton") {//导入button
            const {size, shape, title, action, fileType, data, handleValue} = this.props;
            let props = {
                name: title,//发给后台的文件参数名
                accept: this.getFileType(fileType, "accept"),
                action,
                data: file => ({ // data里存放的是接口的请求参数
                    ...data
                    // file: file, // file 是当前正在上传的文件
                }),
                withCredentials: true,
                showUploadList: false,
                headers: {
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    'token': localStorage.getItem(Constant.NAMESPANCE_TOKEN) ? localStorage.getItem(Constant.NAMESPANCE_TOKEN) : ''
                },
                beforeUpload: file => {
                    let fileName = file.name.toString();
                    const isFile = this.getFileType(fileType, "fileType")[0].indexOf(file.type) > -1 ||
                        this.getFileType(fileType, "fileType")[1].indexOf(fileName.substring(fileName.lastIndexOf("."), fileName.length)) > -1;
                    const sizeFile = file.size > 0;
                    if (!isFile) {
                        message.error(`${file.name} 不是 ${this.getFileType(fileType, "fileType")[1]}类型`);
                        return false;
                    } else if (!sizeFile) {
                        message.error('文件大小为0，不允许上传');
                        return false;
                    }
                    return isFile && sizeFile;
                },
                onChange({file, fileList}) {
                    // console.log(file);
                    let loading;
                    if (file.status === "done") {
                        loading = document.getElementById('ajaxLoading');
                        loading.style.display = 'none';
                        // console.log(file.response.DATA);
                        // console.log(file.response.DATA == []);
                        // console.log(file.response.DATA.length);
                        if ((file.response.DATA || []).length === 0 || file.response.MESSAGE === '上传成功') {
                            message.success("导入成功");
                            handleValue(file);
                        } else {
                            if (file.response.DATA.errMsg === "未知错误") {
                                message.error(file.response.DATA.errMsg);
                            } else {
                                message.error("参数不合法");
                                let info = "";
                                let list = file.response.DATA;

                                let content = <div>
                                    {list.map(item => {
                                        return <div>{item + "；"}<br/></div>;
                                    })}
                                </div>;
                                Modal.error({title: "错误信息", content: content});
                            }
                        }
                    } else if (file.status === "error") {
                        loading = document.getElementById('ajaxLoading');
                        loading.style.display = 'none';
                        message.error("上传失败");
                    } else if (file.status === "uploading") {
                        loading = document.getElementById('ajaxLoading');
                        loading.style.display = 'block';
                    }
                }
            };
            return <div style={{...style}}><Upload {...props} >
                <Button icon={<UploadOutlined/>} type="primary" size={size} shape={shape || "default"}>
                    {name}
                </Button>
            </Upload>
            </div>
        }
    };

    render() {
        return (
            this.initTool()
        );
    }
}

//把store中的数据映射到这个组件变成props
const mapStateToProps = (state) => {
    // console.log("mapStateToProps:\n", state);
    return {
        panes: state.panes,//标签页
        actions: state.actions,//所有权限
        activeKey: state.activeKey//当前标签
    };
};

export default connect(mapStateToProps)(BaseTool);
import axios from 'axios';
import { Modal, message } from 'antd';
import Constant from "../utils/constant";
import { EQUIPMENT_EXCEL_EXPORT, LOGOUT } from "./url";

// export const url = "http://localhost:9002";
// export const url = "http://139.159.154.177:9002";
// export const url1 = "http://139.159.154.177:9002";
// export const url = "http://39.106.131.144:9002";
// export const url1 = "http://39.106.131.144:9002";

// export const url = "http://139.129.48.60:9002";
export const url = "https://ghcs.endinspection.com";
// export const url = "https://ghcs.endinspection.com";
// export const url1 = "http://139.129.48.60:9002";
export const url1 = "https://ghcs.endinspection.com";
// export const url1 = "https://ghcs.endinspection.com";


export const commonUrl = url + "/concrete";
export const picUrl = url + "/upload/picture/";
export const picUrl1 = url1 + "/upload/picture/";

const ignoreUrl = [EQUIPMENT_EXCEL_EXPORT];

// axios.defaults.withCredentials = true;//withCredentials 表示跨域请求时是否需要使用凭证，在这写默认全局配置，会导致了高德api跨域问题，
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export default class Axios {

    static ajax(options) {
        // console.dir(options.data);

        let loading;//\public\index.html
        this.loadingStyle(options, loading, 'block');

        //成功返回resolve,失败reject
        return new Promise((resolve, reject) => {
            axios({
                url: options.url,
                method: options.method,
                baseURL: commonUrl,
                params: options.type === "body" ? '' : (options.data && options.data.params) || '',
                data: options.type === "body" ? (options.data && options.data.params) || '' : '',
                withCredentials: true,
                headers: {
                    'Content-Type': options.type === "body" ? 'application/json;charset=UTF-8' : 'application/x-www-form-urlencoded',
                    'token': localStorage.getItem(Constant.NAMESPANCE_TOKEN) ? localStorage.getItem(Constant.NAMESPANCE_TOKEN) : ''
                },
            }).then(value => {
                // console.log(response);
                this.loadingStyle(options, loading, 'none');
                this.handleData(value, options, resolve, reject);
            }).catch(reason => {
                this.loadingStyle(options, loading, 'none', "catchError", reason);
            })
        });
    }

    //获取省市区列表
    static mapAjax() {
        //成功返回resolve,失败reject
        return new Promise((resolve, reject) => {
            axios.get('https://restapi.amap.com/v3/config/district?subdistrict=4&key=3180bdb5adb845a5d09ac73d9ce28497')
                .then((response) => {
                    // console.log(response);
                    if (response.status == 200) {
                        let res = response.data;
                        if (res.status == '1') {
                            resolve(res);
                        } else {
                            console.log(response);
                        }
                    } else {
                        reject(response.data);
                    }
                }).catch(res => {
                    console.log(res);
                })
        });
    }

    static PostAjax(options) {
        // console.dir(options.data);
        // console.dir(document);
        let loading;
        this.loadingStyle(options, loading, 'block');

        // 成功返回resolve,失败reject，参数resolve和reject的作用是将Promise中函数要传递的值，
        // 作为参数传给后面的then和catch中函数
        return new Promise((resolve, reject) => {
            // console.log(localStorage.getItem("abilityKeyToken"));
            //放外面token值不对
            // axios.defaults.headers.post['token'] = localStorage.getItem(Constant.NAMESPANCE_TOKEN) ? localStorage.getItem(Constant.NAMESPANCE_TOKEN) : '';
            axios.post(
                commonUrl + options.url,
                options.type === "param" ? "" : (options.data && options.data.params) || '',
                {
                    withCredentials: true,
                    params: options.type === "param" ? (options.data && options.data.params) || '' : '',
                    headers: {
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                        'token': localStorage.getItem(Constant.NAMESPANCE_TOKEN) ? localStorage.getItem(Constant.NAMESPANCE_TOKEN) : ''
                    },
                }
            ).then(value => {
                // console.log(options, response);
                this.loadingStyle(options, loading, 'none');
                this.handleData(value, options, resolve, reject);
            }).catch(reason => {
                this.loadingStyle(options, loading, 'none', "catchError", reason);
            })
        });
    }

    //loading样式
    static loadingStyle(options, loading, display, type, reason) {
        // console.log(options.data);
        // console.log(options.data.isShowLoading);//undefined
        // if (options.data && options.data.isShowLoading !== false) {
        loading = document.getElementById('ajaxLoading');
        loading.style.display = display;

        if (type === "catchError") {
            console.log(reason);
            // console.log(reason.response);//直接使用res是打印不出数据的，只有通过res.response才能打印后台返回的数据**
            let res = (reason.response || {}).data;
            if ((res.DATA || {}).errMsg === '用户未登录') {
                message.error("用户未登录");
                window.location.href = '#login';
            } else {
                message.error((res.DATA || {}).errMsg ? (res.DATA || {}).errMsg : res.MESSAGE ? res.MESSAGE : "请求错误！");
            }
        }
    }

    static handleData(response, options, resolve, reject) {
        if (response.status === 200) {
            let res = response.data;
            // console.log(res);
            if (res.STATUS === 200 || res.code === 200 || ignoreUrl.includes(options.url)) {
                resolve(res);
                if (options.url === LOGOUT) localStorage.removeItem(Constant.NAMESPANCE_TOKEN);
            } else {
                Modal.info({
                    title: "提示",
                    // content: res.DATA ? (res.DATA || {}).errMsg === "用户未登录" ? "请登录" : (res.DATA || {}).errMsg : res.MESSAGE
                    content: res.DATA ? (res.DATA || {}) === "用户未登录" ? "请登录" : (res.DATA || {}) : res.MESSAGE
                });
                // if ((res.DATA || {}).errMsg === '用户未登录') {
                if ((res.DATA || {}) === '用户未登录') {
                    window.location.href = '#login';
                }
            }
        } else {
            reject(response.data);
        }
    }
}

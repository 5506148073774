/**
 用户-角色-权限
 */
//用户
export const LOGIN = "/sys-user/login";
export const USER_INSERT = "/sys-user/insertUser";
export const USER_LIST = "/sys-user/selectUsers";
export const USER_INFO = "/sys-user/selectOneUser";
export const USER_UPDATE = "/sys-user/updateOneUser";
export const USER_UPDATE_PASSWORD = "/sys-user/updatePassword";
export const USER_DELETE = "/sys-user/deleteUserById";
export const USER_EXCEL_IMPORT = "/sys-user/excelImport";
export const USER_TYPE = "/sys-user-type/getUserType";
export const LOGOUT = "/sys-user/logout";
//权限
export const AUTH_ADD = "/sys-auth/addAuth";
export const AUTH_LIST = "/sys-auth/selectAuth";
export const AUTH_DELETE = "/sys-auth/deleteListById";
export const AUTH_UPDATE = "/sys-auth/updateAuth";
export const AUTH_ROLE_CONFIG = "/sys-role-auth/configAuth";//给角色配置权限
export const AUTH_ROLE_INFO = "/sys-role-auth/selectAuthByRole";//根据角色查看权限
export const AUTH_ROLE_LIST = "/sys-role-auth/getAllAuthRole";//查看所有的角色对应权限
export const AUTH_ROLE_DELETE = "/sys-role-auth/deleteAuthByRole";//批量删除角色权限关联
export const AUTH_ROLE_UPDATE = "/sys-role-auth/updateAuthByRole";//修改角色权限
//角色
export const ROLE_ADD = "/sys-role/addRole";
export const ROLE_LIST = "/sys-role/selectRole";
export const ROLE_DELETE = "/sys-role/deleteRole";
export const ROLE_UPDATE = "/sys-role/updateRole";
export const ROLE_USER_CONFIG = "/sys-user-role/assignRole";
export const ROLE_USER_INFO = "/sys-user-role/selectRoleByUser";//根据用户查看角色
export const ROLE_USER_LIST = "/sys-user-role/getAllUserRole";//查询所有用户对应角色
export const ROLE_USER_UPDATE = "/sys-user-role/updateRoleByUser";//修改用户的角色
export const ROLE_USER_DELETE = "/sys-user-role/deleteListByIdList";//批量删除用户与角色关联

/**
 * 设备区域
 */
//区域
export const AREA_ADD = "/area/addArea";
export const AREA_EXCEL_IMPORT = "/area/addByExcel";
export const AREA_LIST = "/area/getAreaPage";
export const AREA_EQUIP_LIST = "/area/getAreaEquip";
export const AREA_UPDATE = "/area/updateArea";
export const AREA_DELETE = "/area/deleteArea";
export const AREA_MODEL = "/area/addModel";

//单元
export const UNIT_ADD = "/area-unit/addAreaUnit";
export const UNIT_LIST = "/area-unit/selectAreaUnit";
export const UNIT_UPDATE = "/area-unit/updateAreaUnit";
export const UNIT_DELETE = "/area-unit/deleteAreaUnit";

//用户区域
export const USER_AREA_ADD = "/sys-user-area/addUserArea";
export const USER_AREA_DELETE = "/sys-user-area/deleteUserArea";

//设备
export const EQUIPMENT_EXCEL_EXPORT = "/equipment/generateExcelModel";
export const EQUIPMENT_ADD = "/equipment/addEquipment";
export const EQUIPMENT_EXCEL_IMPORT = "/equipment/addByExcel";
export const EQUIPMENT_LIST = "/equipment/selectEquiPage";
export const EQUIPMENT_DELETE = "/equipment/deleteByIdList";
export const EQUIPMENT_UPDATE = "/equipment/updateById";
export const EQUIPMENT_DELETE_LOCATION = "/equipment/deleteEquipLocation";
export const EQUIPMENT_MODEL_ADD = "/equipment/addModelAxis";

//设备类型
export const EQUIPMENT_TYPE_ADD = "/equipment-type/addEquipmentType";
export const EQUIPMENT_TYPE_LIST = "/equipment-type/getEquiTypePage";
export const EQUIPMENT_TYPE_DELETE = "/equipment-type/deleteEquipTypeByIdList";
export const EQUIPMENT_TYPE_UPDATE = "/equipment-type/updateEquipType";

/*
数据
 */
//接收数据
export const RECEIVE_RECORD_LIST = "/receive-record/getReceiveData";

//腐蚀数据
export const CORROSION_LIST = "/corrosion-data/getCorrosionData";
export const CORROSION_ADD = "/socket/sendToGateway";
export const CORROSION_LIST_COMPARE = "/corrosion-data/selectByTimeId";
export const CORROSION_DATA = "/corrosion-data/getCorrosionDataByArea";

//设备参数
export const EQUIPMENT_PARAM_ADD = "/socket/updateEquipParam";
export const EQUIPMENT_PARAM_LIST = "/equipment-param/getEquipParamPage";


/*
其他
 */
export const UPLOAD_PIC = "/upload/uploadPic";
export const DOWNLOAD_AREA = "/download/下载区域导入模板";
export const DOWNLOAD_USER = "/download/下载用户导入模板";
export const DOWNLOAD_EQUIPMENT = "/download/下载设备导入模板";

//端口
export const PORT_START_TEST = "/socket/portStartTest";
export const PORT_STOP_TEST = "/socket/portStopTest";
export const PORT_STATE_TEST = "/socket/portStateTest";
export const PORT_LIST_TEST = "/socket/getAllState";
export const PORT_START = "/socket-port/startPort";
export const PORT_STOP = "/socket-port/stopPort";
export const PORT_LIST = "/socket-port/getAllState";
export const PORT_STATE = "/socket-port/getOneState";
export const PORT_SEND_MESSAGE = "/socket-port/sendMessage";
import React, {Component} from 'react'
import './styles.less'
import axios from "../../axios";
import connect from "react-redux/es/connect/connect";
import {addUserInfo, refreshUser} from "../../redux/action";
import LoginForm from "./LoginForm";
import Constant from '../../utils/constant';
import {LOGIN} from "../../axios/url";

@connect(
    state => ({}), {
        refreshUser, addUserInfo
    }
)

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {check: 0};
    }

    componentDidMount() {
        //每次进入登录页清除之前的Tabs
        this.props.refreshUser()
    }

    loginSubmit = (params) => {
        // console.log(params);
        axios.PostAjax({
            url: LOGIN,
            data: {
                params: {...params}
            }
        }).then((res) => {
            // console.dir(res);

            localStorage.setItem(Constant.NAMESPANCE_NAME, params.userCode);
            if (this.state.check === 2) {//取消勾选
                localStorage.removeItem(Constant.NAMESPANCE_PASS);
                localStorage.setItem(Constant.NAMESPANCE_STATUS, "false");
                // console.log("unchecked");
            } else if (this.state.check === 0 || this.state.check === 1 || localStorage.getItem(Constant.NAMESPANCE_STATUS) === "true") {
                localStorage.setItem(Constant.NAMESPANCE_PASS, params.password);
                localStorage.setItem(Constant.NAMESPANCE_STATUS, "true");
                // console.log(res.data.token);
                // console.log(typeof res.data.token);
                // console.log(typeof params.password);
                localStorage.setItem(Constant.NAMESPANCE_TOKEN, res.DATA);
                // console.log("checked");
            }

            // localStorage.setItem(Constant.NAMESPANCE_USERTYPE, res.data.userType);
            // this.props.addUserInfo(res.data.loginName, res.data.userId, res.data.userType, res.data.username);
            window.location.href = '#';//跳转页面
        })

    };

    render() {
        // console.log(document.documentElement);
        const height = document.documentElement.clientHeight;

        return (
            <div className="login" style={{height: height}}>
                <div className="login-left" style={{height: height * 0.8}}>
                    <div className="login-left-body">
                        <h2> {Constant.APPLICATION_NAME} </h2>
                    </div>
                </div>
                <div className="login-right" style={{height: height * 0.8}}>
                    <div className="login-right-body">
                        <LoginForm loginSubmit={this.loginSubmit} handleChecked={(check) => this.setState({check})}/>
                    </div>
                </div>
                <footer>
                    <p>版权所有：无线通信与移动计算创新团队</p>
                    <p>联系电话：0532-86983779</p>
                </footer>
            </div>
        );
    }
}

export default Login
import React from "react";

const prefix = 'concrete';

const namespaces = {
    NAMESPANCE_NAME: `${prefix}KeyName`,
    NAMESPANCE_PASS: `${prefix}KeyPass`,
    NAMESPANCE_STATUS: `${prefix}KeyStatus`,
    NAMESPANCE_TOKEN: `${prefix}KeyToken`,
    NAMESPANCE_USERTYPE: `${prefix}UserType`
};

const name = "钢筋混凝土腐蚀无损监测系统";
const application = {
    APPLICATION_NAME: name
};
//title,head,body等标签是缺省的唯一标签，所以 document.title和document.body可以直接找到结果．
// 而title是一个结构的标签，也就是说title内，只能加入nodetext．而不能再加别的元素．于是它的使用又具有特殊性．
// 为了不让代码出错．于是js中规定document.title这个对象只有一个属性（也可以说是没有属性）,
// document.title本身就是document对象的一个属性．而不是它的一个子对象．于用document.title=""来更改title.
document.title = name;

// 指令格式：EE【A2 12 12位手机号 Add_id 电流（2字符） 通电时间（2字符） 断电时间（2字符） Data 校验码】FF FC FF FF
// EEA212015378451234000110101010FFFCFFFF

const form = {
    START_FORM: "EE",
    END_FORM: "FFFCFFFF",
    CORROSION_FORM: "A214",
    EQUIPMENT_FORM: "A212"
};

export default Object.assign({},
    namespaces, application, form // 所有的变量都在这里export导出 多个用逗号隔开
)
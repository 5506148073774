import React from 'react'
import {Table} from 'antd'
import "./style.less"
import {pageSize} from "../../utils";

export default class ETable extends React.Component {

    state = {};

    //处理行点击事件
    onRowClick = (record, index) => {
        let row_selection = this.props.row_selection;

        // console.log(record);
        // console.log(row_selection);
        // console.log(this.props);

        if (row_selection === 'checkbox') {//eslint-disable-line
            let selectedRowKeys = this.props.selectedRowKeys;
            let selectedIds = this.props.selectedIds;
            // console.log(selectedIds);
            if (selectedIds) {
                const i = selectedIds.indexOf(record.id);
                // console.log(selectedIds + " " + selectedRowKeys + " " + record.id + " " + index);
                if (i == -1) {
                    selectedIds.push(record.id);
                    selectedRowKeys.push((pageSize * (((this.props.pagination || {}).current || 1) - 1)) || 0 + index + 1);
                } else {
                    selectedIds.splice(i, 1);
                    selectedRowKeys.splice(i, 1);
                    //删除指条目
                }
            } else {
                selectedIds = [record.id];
                selectedRowKeys = [pageSize * (this.props.pagination.current - 1) + index + 1]
            }
            this.props.updateSelectedItem(selectedRowKeys, record || {}, selectedIds);
        } else {
            let selectKey = [index];
            const selectedRowKeys = this.props.selectedRowKeys;
            // console.log(selectedRowKeys + " " + selectKey);
            if (selectedRowKeys && selectedRowKeys[0] == index) {
                return;
            }
            let selectedIds = [record.id];
            this.props.updateSelectedItem(selectKey, record || {}, selectedIds);
        }
    };

    // 选择框变更
    onSelectChange = (selectedRowKeys, selectedRows) => {
        let {selectedIds, pagination, dataSource} = this.props;
        // console.log(selectedRowKeys);//序号
        // console.log(selectedRows);//完整数据
        // console.log(this.props.selectedRowKeys);//序号。未切页滞后一位；切页第一个为上页数据；切页其他为本页数据
        // console.log(this.props.selectedIds);//id
        // console.log(this.props.pagination.current);

        const selectedRowKeys1 = [];
        const selectedRows1 = [];
        const selectedIds1 = selectedIds || [];
        //移除本页已有数据
        this.removeCurrentData(pagination, dataSource, selectedRowKeys1, selectedRows1, selectedIds1);
        // console.log(selectedRowKeys1, selectedIds1);
        //新增本页数据
        this.addData(selectedRows, selectedRowKeys1, selectedRows1, selectedIds1);
        // console.log(selectedRowKeys1, selectedIds1);

        this.props.updateSelectedItem(selectedRowKeys1, selectedRows[0] || {}, selectedIds1, selectedRows1);

    };

    onSelectAll = (selected, selectedRows) => {
        // console.log(selected);//true;false
        // console.log(selectedRows);//[...]
        // console.log(this.props);

        let {selectedIds, pagination, dataSource} = this.props;

        const selectedRowKeys1 = [];
        const selectedRows1 = [];
        const selectedIds1 = selectedIds || [];

        //移除本页已有数据
        this.removeCurrentData(pagination, dataSource, selectedRowKeys1, selectedRows1, selectedIds1);
        // console.log(selectedRowKeys1, selectedIds1);

        if (selected) {
            //新增本页数据
            this.addData(selectedRows, selectedRowKeys1, selectedRows1, selectedIds1);
        }
        // console.log(selectedRowKeys1, selectedIds1);

        this.props.updateSelectedItem(selectedRowKeys1, selectedRows[0] || {}, selectedIds1, selectedRows1);
    };

    removeCurrentData = (pagination, dataSource, selectedRowKeys, selectedRows, selectedIds) => {
        (this.props.selectedRowKeys || []).map((item, index) => {
            // console.log(Math.floor((item - 1) / 10));//0.8
            // console.log(pagination.current - 1);
            if (Math.floor((item - 1) / 10) !== pagination.current - 1) {
                selectedRowKeys.push(item);
            }
        });
        (this.props.selectedRows || []).map((item, index) => {
            if (Math.floor((item.key - 1) / 10) !== pagination.current - 1) {
                selectedRows.push(item);
            }
        });
        (dataSource || []).map((item) => {
            // console.log(item.id);
            selectedIds.map((item1, index1) => {
                // console.log(item1);
                if (item.id === item1) {
                    selectedIds.splice(index1, 1);
                }
            })
        });
    };

    addData = (selectedRows, selectedRowKeys, selectedRows1, selectedIds) => {
        selectedRows.map((item) => {
            // console.log(item);
            if (item && !selectedRowKeys.includes(item.key)) {//非当前页数据为undefined（全选时） & 重复
                selectedRowKeys.push(item.key);
                selectedRows1.push(item);
                selectedIds.push(item.id);
            }
        });
    };

    getOptions = () => {
        const {selectedRowKeys} = this.props;
        let rowSelection = {
            type: 'radio',
            selectedRowKeys,
            onChange: this.onSelectChange,
            onSelectAll: this.onSelectAll
        };
        let row_selection = this.props.row_selection;
        // console.log(row_selection);
        if (row_selection == 'checkbox') {
            //设置类型为复选框
            rowSelection.type = 'checkbox';
        } else {
            rowSelection = null;
        }

        let pagination = {
            ...this.props.pagination,
            showSizeChanger: false,//是否展示 pageSize 切换器，当 total 大于 50 时默认为 true
        };

        // console.log(rowSelection);
        // console.log(selectedRowKeys);

        return this.props.locale ? <Table
            className="card-wrap page-table"
            bordered//是否展示外边框和列边框
            size={"small"} title={this.props.title || undefined}
            rowClassName={this.props.rowClassName}//表格行的类名
            dataSource={this.props.dataSource}//数据数组
            columns={this.props.columns}//表格列的配置描述
            pagination={pagination}//分页器
            rowSelection={rowSelection}//表格行是否可选择
            locale={this.props.locale}
            // onRow={(record, index) => ({//设置行属性
            //     onClick: () => {// 点击行
            //         if (!row_selection) {
            //             return;
            //         }
            //         this.onRowClick(record, index)
            //     }
            // })}
        /> : <Table
            className="card-wrap page-table"
            bordered//是否展示外边框和列边框
            size={"small"} title={this.props.title || undefined}
            rowClassName={this.props.rowClassName}//表格行的类名
            dataSource={this.props.dataSource}//数据数组
            columns={this.props.columns}//表格列的配置描述
            pagination={pagination}//分页器
            rowSelection={rowSelection}//表格行是否可选择
            // onRow={(record, index) => ({//设置行属性
            //     onClick: () => {// 点击行
            //         if (!row_selection) {
            //             return;
            //         }
            //         this.onRowClick(record, index)
            //     }
            // })}
        />;
    };
    render = () => {
        return (
            <div>
                {this.getOptions()}
            </div>
        )
    }
}
import React, {Component} from 'react';
// import Header from "./component/Header/indexStatic";
import Header from "./component/Header";
import styles from './style/common.less';

export default class App extends Component {
    render() {
        return (
            <div style={{...styles}}>
                <Header/>
            </div>
        );
    }
}
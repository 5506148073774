import React, {Component} from 'react';
import axios from '../../../axios'
import {Button, Collapse, Modal, message, Form} from 'antd'
import Utils from "../../../utils";
import './styles.less'
import RoleForm from "./RoleForm";
import RoleAction from "./roleActiion";
import {AUTH_ROLE_CONFIG, AUTH_ROLE_UPDATE, ROLE_ADD, ROLE_DELETE, ROLE_LIST, ROLE_UPDATE} from "../../../axios/url";
import BasePage from "../../../component/BasePage";
import BaseTool from "../../../component/BaseTool";
import BaseModal from "../../../component/BaseModal";

const ButtonGroup = Button.Group;
const Panel = Collapse.Panel;
const confirm = Modal.confirm;

const formList = [
    {
        type: 'INPUT',
        label: "角色名称",
        field: 'roleName'
    }, {
        type: 'INPUT',
        label: "角色编号",
        field: 'roleCode'
    }, {
        type: 'SELECT',
        label: "状态",
        field: 'status',
        list: [
            {id: 1, name: "开启"},
            {id: 0, name: "关闭"},
        ]
    }

];

class Role extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isClear: false
        };

        this.params = {pageNo: 1};
        this.roleForm = React.createRef();
    }

    componentDidMount() {
        this.getList();
    }

    getList = (res) => {
        axios.PostAjax({
            url: ROLE_LIST,
            data: {
                params: {...res}
            }
        }).then(res => {
            // console.log(res);
            this.setState({
                isClear: true,
                dataSource: res.DATA.map((item, index) => {
                    item.key = index + 1;
                    return item;
                })
            })
        });
    };

    handleOperation = (type, item) => {
        this.setState({type}, () => {
            switch (type) {
                case "view":
                    this.setState({
                        isRoleVisible: true,
                        roleItem: item
                    });
                    break;
                case "edit":
                    this.setState({
                        isRoleVisible: true,
                        roleItem: item
                    });
                    break;
                case "delete":
                    // console.log(item);
                    this.handleDelete(type, item);
                    break;
                case "create":
                    this.setState({
                        isRoleVisible: true
                    });
                    break;
                case "deleteAll":
                    this.handleDelete(type, item);
                    break;
                case "config":
                    this.setState({
                        isRoleAction: true,
                        roleItem: item
                    });
                    break;
                case "configAll":
                    console.log(this.state);
                    if (this.state.record) {
                        this.setState({
                            isRoleAction: true,
                            roleItem: this.state.record
                        });
                    } else {
                        message.info("请选择一个角色");
                    }
                    break;
            }
        });
    };

    //角色提交
    handleRoleSubmit = () => {
        let data = this.roleForm.current.getFieldsValue();
        // console.log(data);
        let reg = /^(?=.*\S).+$/;
        if (!data.roleName) {
            message.error("角色名称不能为空");
        } else if (!reg.test(data.roleName)) {
            message.error("角色名称格式错误");
        } else {
            axios.PostAjax({
                url: this.state.type === "create" ? ROLE_ADD : ROLE_UPDATE,
                data: {
                    params: this.state.type === "create" ? {
                        ...data
                    } : {
                        ...data,
                        id: this.state.roleItem.id
                    }
                }
            }).then((res) => {
                message.success(res.DATA);
                this.setState({isRoleVisible: false});
                this.roleForm.current.resetFields();//清除表单
                this.getList();
            });
        }
    };

    handleDelete = (type, item) => {
        // console.log(this.state.selectedItem, this.state.record, this.state.selectedIds);
        let count = (this.state.selectedIds || []).length;
        // console.log(this.state.selectedIds);
        // console.log(this.state.selectedIds.toString());
        if (type === "delete" || (type === "deleteAll" && count > 0)) {
            confirm({
                title: type === "delete" ? '确认删除?' : '确认删除' + count + '项?',
                okText: '是',
                okType: 'danger',
                cancelText: '否',
                onOk: () => {
                    axios.ajax({
                        url: ROLE_DELETE,
                        method: "delete",
                        data: {
                            params: type === "delete" ? {idList: item.id} : {idList: this.state.selectedIds.toString()}
                        }
                    }).then((res) => {
                        message.success(res.DATA);
                        if (res.MESSAGE === "请求成功") {
                            this.getList();
                        }
                    })
                }
            });
        } else if (count === 0) {
            message.info("请选择角色");
        }
    };

    handleClose = () => {
        if (this.roleForm.current) this.roleForm.current.resetFields();
        this.setState({isRoleVisible: false, isRoleAction: false, roleItem: {}});
    };

    //权限配置
    handleRoleAction = () => {
        // let actionIds = [];
        // (this.state.actionList || []).map((item) => {
        //     actionIds.push(item.id);
        // });

        let data = [];
        (this.state.actionList || []).map((item) => {
            // console.log(item, this.state);
            data.push({
                roleCode: this.state.roleItem.roleCode,
                authCode: item.authCode
            });
        });

        if (data.length === 0) {
            message.error("请选择权限");
        } else {
            axios.PostAjax({
                url: AUTH_ROLE_UPDATE,
                data: {
                    params: data
                }
            }).then((res) => {
                message.success(res.DATA);
                this.setState({
                    isRoleAction: false
                })
            })
        }
    };

    render() {
        let {type, roleItem, selectedIds, isRoleAction, isClear} = this.state;

        const columns = [
            {
                title: '序号',
                dataIndex: 'key'
            }, {
                title: '角色编号',
                dataIndex: 'roleCode'
            }, {
                title: '角色名称',
                dataIndex: 'roleName'
            }, {
                title: '操作时间',
                dataIndex: 'operationTime',
                render: Utils.formatDate
            }, {
                title: '使用状态',
                dataIndex: 'status',
                render(status) {
                    if (status === 1) {
                        return "启用"
                    } else {
                        return "停用"
                    }
                }
            },
            {
                title: '操作',
                render: (text, record) => (
                    <div>
                        <BaseTool type="button" name="查看" onClick={() => this.handleOperation("view", record)}/>
                        <BaseTool type="button" name="编辑" onClick={() => this.handleOperation("edit", record)}/>
                        <BaseTool type="button" name="分配权限" onClick={() => this.handleOperation("config", record)}/>
                        <BaseTool type="button" name="删除" display
                                  onClick={() => this.handleOperation("delete", record)}/>
                    </div>
                )
            }
        ];

        let head = <div style={{
            position: "relative",
            float: "right",
            marginBottom: "20px",
            // display: this.props.userType === 2 ? "none" : "block"//只对管理员显示
        }}>
            <BaseTool type="headButton" name="删除角色" onClick={() => this.handleOperation("deleteAll")}/>
            {/*<BaseTool type="headButton" name="设置权限" onClick={() => this.handleOperation("configAll")}/>*/}
            <BaseTool type="headButton" name="创建角色" onClick={() => this.handleOperation("create")}/>
        </div>;

        return (
            <div className="role">
                <BasePage formList={formList} head={head} columns={columns}
                          url={ROLE_LIST} dataSource={this.state.dataSource || []}
                          getList={(res) => this.getList(res)} isClear={isClear}
                          updateClearFlag={() => this.setState({isClear: false})}
                          updateSelectedItem={(selectedRowKeys, record, selectedIds) => {
                              // console.log(selectedRowKeys, record, selectedIds);
                              this.setState({selectedRowKeys, record, selectedIds})
                          }}/>
                <BaseModal
                    width={600}
                    title={type === "create" ? "创建角色" : type === "edit" ? "修改角色" : "查看角色"}
                    visible={this.state.isRoleVisible}
                    onCancel={() => this.handleClose()}
                    footer={type === "view" ?
                        [
                            <Button type="primary" onClick={() => this.handleClose()}> 确定 </Button>,
                        ] :
                        [
                            <Button type="primary" onClick={() => this.handleRoleSubmit()}> 提交 </Button>,
                            <Button type="primary" onClick={() => this.handleClose()}> 取消 </Button>,
                        ]}
                    content={<RoleForm form={this.roleForm} type={type} roleItem={this.state.roleItem || {}}/>}
                />
                <BaseModal
                    width={400}
                    title="分配权限"
                    visible={isRoleAction}
                    onOk={this.handleRoleAction}
                    okText={"保存"}
                    onCancel={this.handleClose}
                    content={
                        <RoleAction roleItem={roleItem} selectedIds={selectedIds} type={type}
                                    dispatchRoleAction={(data) => {
                                        // console.log(data);
                                        this.setState({
                                            actionList: data
                                        })
                                    }}/>}
                />
            </div>
        );
    }
}

export default Role;
import React, {Component} from 'react';
import BasePage from "../../../component/BasePage";
import {Avatar, Button, Cascader, Col, Form, Image, Input, message, Modal, Radio, Row, Select} from "antd";
import BaseTool from "../../../component/BaseTool";
import BaseModal from "../../../component/BaseModal";
import EquipmentManage from "../equipmentManage";
import axios, {commonUrl, picUrl, picUrl1, url, url1} from '../../../axios'
import {getTreeData} from "../../../utils/dataDeal";
import {
    AREA_ADD, AREA_DELETE, AREA_EQUIP_LIST,
    AREA_EXCEL_IMPORT, AREA_LIST, AREA_MODEL, AREA_UPDATE, DOWNLOAD_AREA, UPLOAD_PIC, USER_UPDATE
} from "../../../axios/url";
import './styles.less';
import Utils, {pageSize} from "../../../utils";
import UnitManage from "../unitManage";

const FormItem = Form.Item;
const Option = Select.Option;
const confirm = Modal.confirm;

const formItemLayout = {labelCol: {span: 6}, wrapperCol: {span: 16}};

export default class AreaManage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            area: [],

            areaItem: {},

            isClear: false
        };
        this.form = React.createRef();

        this.params = {pageNo: 1};
        this.params1 = {};//筛选参数
    }

    componentDidMount() {
        this.getList();
        this.getMap();
    }

    getMap = () => {
        axios.mapAjax().then(res => {
            // console.log(res);
            this.setState({
                area: getTreeData(res.districts[0].districts)
            });
        });
    };

    getList = (res) => {
        axios.PostAjax({
            url: this.props.propsType === "select" ? AREA_EQUIP_LIST : AREA_LIST,
            data: {
                params: {...this.params1, current: this.params.pageNo, size: pageSize}
            }
        }).then(res => {
            this.setState({
                isClear: true,
                dataSource: ((this.props.propsType === "select" ? res.DATA.areaEquipParamList : res.DATA.areaList) || [])
                    .map((item, index) => {
                        item.key = pageSize * (this.params.pageNo - 1) + index + 1;
                        return item;
                    }),
                pagination: Utils.pagination(res.DATA, (current) => {
                    this.params.pageNo = current;//当前页数
                    this.getList(); //刷新列表数据
                })
            })
        });
    };

    handleOperation = (type, record) => {
        switch (type) {
            case "view":
                this.setState({
                    areaItem: record,
                    areaVisible: true
                });
                break;
            case "change":
                this.setState({
                    areaItem: record,
                    areaVisible: true
                });
                break;
            case "add":
                this.setState({areaVisible: true});
                break;
            case "delete":
                this.handleDelete(type, record);
                break;
            case "deleteAll":
                this.handleDelete(type, record);
                break;
            case "template":
                window.open(url + DOWNLOAD_AREA, "_parent");
                break;
            case "unit":
                this.setState({
                    areaItem: record,
                    unitVisible: true
                });
                break;
            case "equip":
                this.setState({
                    areaItem: record,
                    equipVisible: true
                });
                break;
            case "choose":
                this.props.handleClick(record);
                break;
            case "model":
                this.setState({
                    areaItem: record,
                    icon: record.modelUrl,
                    modelVisible: true
                });
                break;
        }

        this.setState({type});
    };

    resetList = (res) => {
        if (res) this.params1 = res;
        this.params.pageNo = 1;
        this.getList();
    };

    handleOk = (type) => {
        let data = this.form.current.getFieldsValue();
        console.log(data);

        axios.PostAjax({
            url: this.state.type === "add" ? AREA_ADD : AREA_UPDATE,
            data: {
                params: this.state.type === "add" ? {
                    ...data,
                    areaLocation: data.areaLocation.toString()
                } : {
                    ...data,
                    areaLocation: data.areaLocation.toString(),
                    areaId: this.state.areaItem.areaId
                }
            }
        }).then((res) => {
            message.success(res.DATA);
            this.onCancel();
            this.resetList();
        });
    };

    addModel = () => {
        let {icon, areaItem} = this.state;

        axios.ajax({
            url: AREA_MODEL,
            data: {
                params: {url: icon, areaId: areaItem.areaId}
            }
        }).then((res) => {
            message.success(res.DATA);
            this.onCancel();
            this.resetList();
        });
    };

    handleDelete = (type, item) => {
        let count = (this.state.selectedIds || []).length;
        if (type === "delete" || (type === "deleteAll" && count > 0)) {
            confirm({
                title: type === "delete" ? '确认删除?' : '确认删除' + count + '项?',
                okText: '是',
                okType: 'danger',
                cancelText: '否',
                onOk: () => {
                    axios.ajax({
                        url: AREA_DELETE,
                        method: "delete",
                        data: {
                            params: type === "delete" ? {idList: item.id} : {idList: this.state.selectedIds.toString()}
                        }
                    }).then((res) => {
                        message.success(res.DATA);
                        this.resetList();
                    })
                }
            });
        } else if (count === 0) {
            message.info("请选择区域");
        }
    };

    onCancel = () => {
        if (this.form.current) this.form.current.resetFields();
        this.setState({
            areaVisible: false,
            unitVisible: false,
            equipVisible: false,
            modelVisible: false,
            areaItem: {},
            icon: this.state.areaItem.modelUrl || ''
        });
    };

    render() {
        let {type, areaItem, isClear, icon} = this.state;
        let {propsType} = this.props;
        let disabled = type === "view";
        propsType = propsType === "equipment" || propsType === "unit" || propsType === "select";//equipmentManagement

        let head = propsType ? null :
            <div style={{
                position: "relative",
                float: "right",
                marginBottom: "20px",
                // display: this.props.userType === 2 ? "none" : "block"//只对管理员显示
            }}>
                <BaseTool type="headButton" name="删除区域" display
                          onClick={() => this.handleOperation("deleteAll")}/>
                <BaseTool type="importButton" name="导入区域" fileType="excel" display
                          style={{float: "right", marginRight: "20px"}}
                          title='file' action={commonUrl + AREA_EXCEL_IMPORT}
                          handleValue={() => this.resetList()}/>
                <BaseTool type="headButton" name="下载模板" display
                          onClick={() => this.handleOperation("template")}/>
                <BaseTool type="headButton" name="新增区域" display
                          onClick={() => this.handleOperation("add")}/>
            </div>;

        const formList = [
            {
                type: 'INPUT',
                label: "区域名称",
                field: 'areaName',
            }, {
                type: 'INPUT',
                label: "区域省市",
                field: 'areaLocation',
            }, {
                type: 'INPUT',
                label: "详细地址",
                field: 'areaLocationAddress',
            }, {
                type: 'INPUT',
                label: "区域端口号",
                field: 'areaPort',
            }
        ];

        const columns = [
            {
                title: '序号',
                dataIndex: 'key'
            }, {
                title: '区域名称',
                dataIndex: 'areaName'
            }, {
                title: '区域地址',
                dataIndex: 'areaLocation',
                render: (text, item) => {
                    return item.areaLocation.replace(/,/g, "") + item.areaLocationAddress;
                }
            }, {
                title: '区域端口号',
                dataIndex: 'areaPort'
            },{
                title: '操作',
                dataIndex: 'operation',
                render: (text, record) => {
                    if (!propsType) {
                        return (
                            <div>
                                <BaseTool type="button" name="查看详情"
                                          onClick={() => this.handleOperation("view", record)}/>
                                <BaseTool type="button" name="修改信息"
                                          onClick={() => this.handleOperation("change", record)}/>
                                <BaseTool type="button" name="模型管理"
                                          onClick={() => this.handleOperation("model", record)}/>
                                <BaseTool type="button" name="单元管理"
                                          onClick={() => this.handleOperation("unit", record)}/>
                                <BaseTool type="button" name="设备管理"
                                          onClick={() => this.handleOperation("equip", record)}/>
                                <BaseTool type="button" name="删除" display
                                          onClick={() => this.handleOperation("delete", record)}/>
                            </div>
                        );
                    } else {
                        return (
                            <div>
                                <BaseTool type="button" name="查看详情"
                                          onClick={() => this.handleOperation("view", record)}/>
                                <BaseTool type="button" name="选择"
                                          onClick={() => this.handleOperation("choose", record)}/>
                            </div>
                        );
                    }
                }
            }];

        return (
            <div className="area">
                <BasePage formList={formList} head={head} columns={columns}
                          dataSource={this.state.dataSource} isClear={isClear}
                          updateClearFlag={() => this.setState({isClear: false})}
                          getList={(res) => this.resetList(res)}
                          pagination={this.state.pagination}
                          rowSelection={propsType ? "null" : "checkbox"}
                          updateSelectedItem={(selectedRowKeys, record, selectedIds) => {
                              // console.log(selectedRowKeys, record, selectedIds);
                              this.setState({selectedRowKeys, record, selectedIds})
                          }}/>
                <BaseModal
                    width={600}
                    title={type === "add" ? "新增区域" : type === "change" ? "修改区域" : "查看区域"}
                    visible={this.state.areaVisible}
                    onCancel={this.onCancel}
                    footer={null}
                    content={<Form ref={this.form} onFinish={() => this.handleOk()} scrollToFirstError
                                   fields={
                                       [
                                           {"name": "areaName", "value": areaItem.areaName || ''},
                                           {
                                               "name": "areaLocation",
                                               "value": (areaItem.areaLocation || "").split(",") || ''
                                           },
                                           {"name": "areaLocationAddress", "value": areaItem.areaLocationAddress || ""},

                                           {"name": "areaPort", "value": areaItem.areaPort || ""},
                                           // {"name": "status", "value": areaItem.status || 1},
                                           // {"name": "remark", "value": areaItem.remark || ''}
                                       ]
                                   }>
                        <FormItem label="区域名称" {...formItemLayout} name='areaName'
                                  rules={[{required: true, message: '请输入区域名称！'}]}>
                            <Input placeholder="" disabled={disabled}/>
                        </FormItem>
                        {type !== "view" ?
                            <FormItem label="区域地址"  {...formItemLayout} name='areaLocation'
                                      rules={[{required: true, message: '请选择区域地址！'}]}>
                                <Cascader fieldNames={{label: 'name', value: 'name', children: 'districts'}}
                                          options={this.state.area || [{name: '数据加载中...', disabled: true,},]}
                                          placeholder={""} disabled={disabled}/>
                            </FormItem> :
                            <FormItem label="区域地址"  {...formItemLayout} name='areaLocation'
                                      rules={[{required: true, message: '请输入区域地址！'}]}>
                                <Input disabled={disabled}/>
                            </FormItem>
                        }
                        <FormItem label="区域地址详情"  {...formItemLayout} name='areaLocationAddress'
                                  rules={[{required: true, message: '请输入区域地址详情！'}]}>
                            <Input disabled={disabled}/>
                        </FormItem>

                        <FormItem label="区域端口号"  {...formItemLayout} name='areaPort'
                                  rules={[{required: true, message: '请输入区域端口号！'}]}>
                            <Input disabled={disabled}/>
                        </FormItem>
                        {/*<FormItem label="状态" name="status"{...formItemLayout}>*/}
                        {/*<Select disabled={disabled}>*/}
                        {/*<Option key={1} value={1}>开启</Option>*/}
                        {/*<Option key={0} value={0}>关闭</Option>*/}
                        {/*</Select>*/}
                        {/*</FormItem>*/}
                        {/*<FormItem label="备注" name='remark'{...formItemLayout}>*/}
                        {/*<Input.TextArea type="text" autosize={{minRows: 4, maxRows: 6}}*/}
                        {/*placeholder="请输入备注" disabled={disabled}/>*/}
                        {/*</FormItem>*/}
                        <Form.Item>
                            {type === "view" ?
                                <Row>
                                    <Col span={24} style={{textAlign: 'center'}}>
                                        <Button type="primary" onClick={this.onCancel}>
                                            确定
                                        </Button>
                                    </Col>
                                </Row> :
                                <Row>
                                    <Col span={12} style={{textAlign: 'right'}}>
                                        <Button type="primary" onClick={this.onCancel}>
                                            取消
                                        </Button>
                                    </Col>
                                    <Col span={12} style={{textAlign: 'left', paddingLeft: 30}}>
                                        <Button type="primary" htmlType="submit">
                                            提交
                                        </Button>
                                    </Col>
                                </Row>
                            }
                        </Form.Item>
                    </Form>}/>
                <BaseModal
                    width={1200}
                    title="单元管理"
                    visible={this.state.unitVisible}
                    onCancel={() => {
                        this.setState({unitVisible: false})
                    }}
                    footer={null}
                    content={<UnitManage propsType="area" areaItem={areaItem}/>}/>
                <BaseModal
                    width={1200}
                    title="设备管理"
                    visible={this.state.equipVisible}
                    onCancel={() => {
                        this.setState({equipVisible: false})
                    }}
                    footer={null}
                    content={<EquipmentManage propsType="area" areaItem={areaItem}/>}/>
                <BaseModal
                    width={800}
                    title="模型管理"
                    visible={this.state.modelVisible}
                    onCancel={() => {
                        this.setState({modelVisible: false})
                    }}
                    footer={null}
                    content={
                        <div style={{textAlign: 'center'}}>
                            <Image width={600} src={picUrl1 + icon || ''}/>
                            <Row>
                                <Col span={8} style={{textAlign: 'right', marginLeft: 80}}>
                                    <BaseTool type="importButton" fileType='image' name="上传模型图"
                                              title='multipartFile' action={url1 + UPLOAD_PIC} size="middle"
                                              handleValue={(file) => this.setState({icon: file.response.DATA})}/>
                                </Col>
                                <Col span={8} style={{textAlign: 'center', marginLeft: -50}}>
                                    <Button type="primary" onClick={this.onCancel}>
                                        取消
                                    </Button>
                                </Col>
                                <Col span={8} style={{textAlign: 'left', marginLeft: -50}}>
                                    <Button type="primary" onClick={this.addModel}>
                                        提交
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    }/>
            </div>
        );
    }
}
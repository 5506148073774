import React, {Component} from "react";
import {Card, Collapse, Select} from "antd";
import './index.less';
import BaseForm from '../BaseForm';
import Utils from "../../utils";
import ETable from "../ETable";
import axios from "../../axios";

const Panel = Collapse.Panel;

export default class BasePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: props.columns,//Table columns
            formList: props.formList || [],//搜索
            head: props.head,//表格上按钮
            dataSource: props.dataSource || [],

            selectedRowKeys: props.selectedRowKeys || [],
            selectedRows: props.selectedRows || [],
            selectedIds: props.selectedIds || [],
            selectedItem: {}
        };
    }

    handleFilterSubmit = (filterParams) => {
        // console.log(filterParams);
        this.props.getList(filterParams);
    };

    updateSelectedItem = (selectedRowKeys, selectedRow, selectedIds, selectedRows) => {
        // console.log(selectedRowKeys, selectedRows, selectedIds);
        // console.log(this.props.updateSelectedItem);
        if (selectedIds) {
            this.setState({
                selectedRowKeys,
                selectedRows,
                selectedIds,
                selectedItem: selectedRow
            });
        } else {
            this.setState({
                selectedRowKeys,
                selectedRows,
                selectedItem: selectedRow
            })
        }

        if (this.props.updateSelectedItem) {
            // console.log("updateSelectedItem");
            this.props.updateSelectedItem(selectedRowKeys, selectedRow || {}, selectedIds, selectedRows);
        }
    };

    render() {
        const {columns, head} = this.state;
        const {dataSource, rowSelection, formList, isFixed, isClear, updateClearFlag, pagination} = this.props;//formList重新从props里获取，否则就是初始的空
        let {selectedRowKeys, selectedRows, selectedIds, selectedItem} = this.state;
        // console.log(dataSource);
        // console.log(isClear);
        // console.log(pagination);
        // console.log(this.props);

        if (isClear) {//清除复选框
            // console.log(selectedRowKeys, selectedIds, selectedItem);
            this.setState({
                selectedRowKeys: [],
                selectedRows: [],
                selectedIds: [],
                selectedItem: {}
            });
            updateClearFlag();
        }

        return (
            <div>
                {(formList || []).length === 0 ?
                    <div/> :
                    <Collapse accordion defaultActiveKey={['1']} style={{marginTop: 10}}>
                        {!isFixed ?
                            <Panel header="查询" key="1">
                                <BaseForm formList={formList} filterSubmit={this.handleFilterSubmit}/>
                            </Panel> :
                            <Panel header="查询" key="1" showArrow={false} collapsible="disabled">
                                <BaseForm formList={formList} button={false} filterSubmit={this.handleFilterSubmit}/>
                            </Panel>}
                    </Collapse>
                }
                <Card style={{marginTop: 50}}>
                    {head}
                    <div className="warning_list" style={{margin: "10px"}}>
                        <ETable
                            updateSelectedItem={this.updateSelectedItem}
                            selectedRowKeys={selectedRowKeys}
                            selectedIds={selectedIds}
                            selectedItem={selectedItem}
                            selectedRows={selectedRows}
                            dataSource={dataSource}
                            pagination={pagination}
                            columns={columns}
                            row_selection={rowSelection || 'checkbox'}/>
                    </div>
                </Card>
            </div>
        );
    }
}
export const type = {
    ADD_TABS: 'ADD_TABS',
    CHANGE_TABS: 'CHANGE_TABS',
    CLEAR_TABS: 'CLEAR_TABS',
    REFRESH_USER: 'REFRESH_USER',
    ADD_USER_INFO: 'ADD_USER_INFO',
    ADD_USER_ACTION: 'ADD_USER_ACTION'
};

export const addTabs = (panes, activeKey) => {
    // console.log(panes, activeKey);
    return {
        panes,
        activeKey,
        type: type.ADD_TABS,
    }
};

export const changeTabs = (activeKey) => {
    return {
        activeKey,
        type: type.CHANGE_TABS,
    }
};

export const clearTabs = () => {
    return {
        type: type.CLEAR_TABS,
    }
};

export const refreshUser = () => {
    return {
        type: type.REFRESH_USER,
    }
};

export const addUserInfo = (loginName, userId, userType, username) => {
    return {
        loginName,
        userId,
        userType,
        username,
        type: type.ADD_USER_INFO,
    }
};

export const addUserAction = (actions) => {
    return {
        actions,
        type: type.ADD_USER_ACTION,
    }
};
import {type} from '../action';

const panes = [
    {
        content: '首页',
        title: '首页',
        key: '0',
        closable: false,
    }
];

const defaultState = {
    panes: panes,
    activeKey: panes[0].key,
    userId: null,
    userType: null,
    username: null,
    actions: []
};

//描述 action 如何改变 state 树
export default (state = defaultState, action) => {
    // console.log(action);
    switch (action.type) {
        case type.ADD_TABS:
            return {
                ...state,
                panes: action.panes,
                activeKey: action.activeKey
            };
        case type.CHANGE_TABS:
            return {
                ...state,
                activeKey: action.activeKey
            };
        case type.CLEAR_TABS:
            return {
                ...state,
                panes: [
                    {
                        content: '首页',
                        title: '首页',
                        key: '0',
                        closable: false,
                    }
                ],
                activeKey: panes[0].key
            };
        case type.REFRESH_USER:
            return {
                ...defaultState,
                panes: [
                    {
                        content: '首页',
                        title: '首页',
                        key: '0',
                        closable: false,
                    }
                ]
            };
        case type.ADD_USER_INFO:
            return {
                ...state,
                loginName: action.loginName,
                userId: action.userId,
                userType: action.userType,
                username: action.username
            };
        case type.ADD_USER_ACTION:
            return {
                ...state,
                actions: action.actions
            };
        default:
            return state
    }
}
import React, {Component} from 'react'
import loadable from "../../utils/loadable";

const Home = loadable(() => import('../../container/home'));
const User = loadable(() => import('../../container/manage/userManage'));
const Role = loadable(() => import('../../container/manage/roleManage'));
const Permission = loadable(() => import('../../container/manage/permissionManage'));
const Area = loadable(() => import('../../container/manage/areaManage'));
const Unit = loadable(() => import('../../container/manage/unitManage'));
const Equipment = loadable(() => import('../../container/manage/equipmentManage'));
const EquipTyp = loadable(() => import('../../container/manage/equipTypeManage'));
const EquipmentParam = loadable(() => import('../../container/data/equipmentData'));
const Corrosion = loadable(() => import('../../container/data/corrosionData'));
const ReceiveData = loadable(() => import('../../container/data/receiveData'));
const DataDisplay = loadable(() => import('../../container/data/dataDisplay'));
const Port = loadable(() => import('../../container/manage/portManage'));
const Model = loadable(() => import('../../container/manage/modelManage'));
const ModelData = loadable(() => import('../../container/manage/modelManage/ModelView'));
const PersonCenter = loadable(() => import('../../container/personCenter'));

//主体内容
export default class Content extends Component {

    getContent = () => {
        // console.dir(this.props);
        switch (this.props.name) {
            case '首页':
                return <Home/>;
            case '用户管理':
                return <User/>;
            case '角色管理':
                return <Role/>;
            case '权限管理':
                return <Permission/>;
            case '区域管理':
                return <Area/>;
            case '单元管理':
                return <Unit/>;
            case '设备管理':
                return <Equipment/>;
            case '设备类型管理':
                return <EquipTyp/>;
            case '设备参数':
                return <EquipmentParam/>;
            case '腐蚀数据':
                return <Corrosion/>;
            case '接收数据':
                return <ReceiveData/>;
            case '数据展示':
                return <DataDisplay/>;
            case '端口管理':
                return <Port/>;
            case '模型管理':
                return <Model/>;
            case '模型查看':
                return <ModelData/>;
            case '个人中心':
                return <PersonCenter/>;
            default:
                return <div/>;
        }
    };

    render() {
        return (
            <div className='content'>
                <div className='mainContent'>
                    {this.getContent()}
                </div>
            </div>
        );
    }
}

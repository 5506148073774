import React, {Component} from 'react';
import BasePage from "../../../component/BasePage";
import {Button, Col, Form, Input, message, Modal, Row, Select} from "antd";
import BaseTool from "../../../component/BaseTool";
import BaseModal from "../../../component/BaseModal";
import axios, {commonUrl, url} from '../../../axios'
import {
    DOWNLOAD_EQUIPMENT,
    EQUIPMENT_ADD,
    EQUIPMENT_DELETE,
    EQUIPMENT_EXCEL_EXPORT,
    EQUIPMENT_EXCEL_IMPORT,
    EQUIPMENT_LIST,
    EQUIPMENT_TYPE_LIST,
    EQUIPMENT_UPDATE,
} from "../../../axios/url";
import AreaManage from "../areaManage";
import './styles.less';
import Utils, {pageSize} from "../../../utils";
import UnitManage from "../unitManage";
import {setStatus} from "../../../utils/dataDeal";

const FormItem = Form.Item;
const Option = Select.Option;
const confirm = Modal.confirm;

const formItemLayout = {labelCol: {span: 6}, wrapperCol: {span: 16}};

export default class EquipmentManage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            equipItem: props.propsType === "area" ? {...props.areaItem} : {},

            equipType: [],

            isClear: false
        };
        this.form = React.createRef();

        this.params = {pageNo: 1};
        this.params1 = {};//筛选参数
    }

    componentDidMount() {
        this.getList();
        this.getEquipType();
    }

    getList = () => {
        let {propsType, areaItem, unitItem, equipParam} = this.props;
        let data =
            propsType === "area" ? {
                    ...this.params1, areaId: areaItem.areaId
                } :
                propsType === "unit" ? {
                    ...this.params1, areaId: unitItem.areaId, equiUnit: unitItem.equiUnit
                } : propsType === "select" ? {
                    ...this.params1, areaId: equipParam.areaId
                } : {...this.params1};

        axios.PostAjax({
            url: EQUIPMENT_LIST,
            data: {
                params: {
                    ...data, current: this.params.pageNo, size: pageSize
                }
            }
        }).then(res => {
            this.setState({
                isClear: true,
                dataSource: (res.DATA.equipmentList || []).map((item, index) => {
                    item.key = pageSize * (this.params.pageNo - 1) + index + 1;
                    return item;
                }),
                pagination: Utils.pagination(res.DATA, (current) => {
                    this.params.pageNo = current;//当前页数
                    this.getList(); //刷新列表数据
                })
            })
        });
    };

    getEquipType = () => {
        axios.PostAjax({
            url: EQUIPMENT_TYPE_LIST,
            data: {
                params: {}
            }
        }).then((res) => {
            // console.log(res);
            this.setState({equipType: res.DATA.equipmentTypeList});
        });
    };

    handleOperation = (type, record) => {
        switch (type) {
            case "view":
                this.setState({
                    equipItem: record,
                    equipVisible: true
                });
                break;
            case "change":
                this.setState({
                    equipItem: record,
                    equipVisible: true
                });
                break;
            case "add":
                this.setState({equipVisible: true});
                break;
            case "delete":
                this.handleDelete(type, record);
                break;
            case "deleteAll":
                this.handleDelete(type, record);
                break;
            case "template":
                this.setState({areaVisible: true, template: true});
                break;
            case "choose":
                this.props.handleClick(record);
                break;
        }

        this.setState({type});
    };

    resetList = (res) => {
        if (res) this.params1 = res;
        this.params.pageNo = 1;
        this.getList();
    };

    handleOk = (type) => {
        let {propsType, areaItem, unitItem} = this.props;
        let areaId = propsType === "area" ? areaItem.areaId : propsType === "unit" ? unitItem.areaId : this.state.equipItem.areaId;
        let areaName = propsType === "area" ? areaItem.areaName : propsType === "unit" ? unitItem.areaName : this.state.equipItem.areaName;
        let equiUnit = propsType === "unit" ? unitItem.equiUnit : this.state.equipItem.equiUnit;
        let data = this.form.current.getFieldsValue();
        // console.log(data);
        // console.log(this.state);

        axios.PostAjax({
            url: this.state.type === "add" ? EQUIPMENT_ADD : EQUIPMENT_UPDATE,
            data: {
                params: this.state.type === "add" ? {
                    ...data, areaId, areaName, equiUnit
                } : {
                    ...data, areaId, areaName, equiUnit, id: this.state.equipItem.id
                }
            }
        }).then((res) => {
            message.success(res.DATA);
            this.onCancel();
            this.resetList();
        });
    };

    handleDelete = (type, item) => {
        let count = (this.state.selectedIds || []).length;
        let id = [];
        if (type === 'delete') {
            id.push(item.id);
        }

        if (type === "delete" || (type === "deleteAll" && count > 0)) {
            confirm({
                title: type === "delete" ? '确认删除?' : '确认删除' + count + '项?',
                okText: '是',
                okType: 'danger',
                cancelText: '否',
                onOk: () => {
                    axios.ajax({
                        url: EQUIPMENT_DELETE,
                        method: "delete",
                        type: 'body',
                        data: {
                            params: type === "delete" ? id : this.state.selectedIds
                        }
                    }).then((res) => {
                        message.success(res.DATA);
                        this.resetList();
                    })
                }
            });
        } else if (count === 0) {
            message.info("请选择设备");
        }
    };

    onCancel = () => {
        if (this.form.current) this.form.current.resetFields();
        this.setState({equipVisible: false, equipItem: {}});
    };

    render() {
        let {type, equipItem, equipType, isClear, template} = this.state;
        let {areaItem, propsType} = this.props;
        let disabled = type === "view";
        let propsType1 = propsType === "select";
        let propsType2 = propsType === "area";//areaManagement
        let propsType3 = propsType === "unit";//unitManagement
        // console.log(this.state);

        let head = propsType1 ? null :
            <div style={{
                position: "relative",
                float: "right",
                marginBottom: "20px",
                // display: this.props.userType === 2 ? "none" : "block"//只对管理员显示
            }}>
                <BaseTool type="headButton" name="删除设备" onClick={() => this.handleOperation("deleteAll")}/>
                <BaseTool type="importButton" name="导入设备" fileType="excel" style={{float: "right", marginRight: "20px"}}
                          title='file' action={commonUrl + EQUIPMENT_EXCEL_IMPORT}
                          handleValue={() => this.resetList()}/>
                <BaseTool type="headButton" name="下载模板" onClick={() => this.handleOperation("template")}/>
                <BaseTool type="headButton" name="新增设备" onClick={() => this.handleOperation("add")}/>
            </div>;

        const columns = [
            {
                title: '序号',
                dataIndex: 'key'
            }, {
                title: '所属区域',
                dataIndex: 'areaName',
            }, {
                title: '所属单元',
                dataIndex: 'equiUnit',
            }, {
                title: '设备编号',
                dataIndex: 'equiId'
            }, {
                title: '设备名称',
                dataIndex: 'equiName'
            },
            {
                title: '设备位置信息',
                dataIndex: 'equiLocation',
            },
            {
                title: '设备类型',
                dataIndex: 'equiTypeId',
                render: (text) => {
                    let name = text;
                    // console.log(this.state.equipType);//有值
                    // console.log(equipType);//无值？
                    let data = (this.state.equipType || []).find((item) => item.id === text) || {};
                    return data.equiTypeName;
                }
            }, {
                title: '检测部位',
                dataIndex: 'monitorSite'
            }, {
                title: '布设方向',
                dataIndex: 'layoutDetection'
            }, {
                title: '状态',
                dataIndex: 'status',
                render: (text, record) => {
                    return setStatus(text);
                }
            }, {
                title: '操作',
                dataIndex: 'operation',
                render: (text, record) => {
                    if (!propsType1) {
                        return (
                            <div>
                                <BaseTool type="button" name="查看详情"
                                          onClick={() => this.handleOperation("view", record)}/>
                                <BaseTool type="button" name="修改信息"
                                          onClick={() => this.handleOperation("change", record)}/>
                                <BaseTool type="button" name="删除"
                                          onClick={() => this.handleOperation("delete", record)}/>
                            </div>
                        );
                    } else {
                        return (
                            <div>
                                <BaseTool type="button" name="查看详情"
                                          onClick={() => this.handleOperation("view", record)}/>
                                <BaseTool type="button" name="选择"
                                          onClick={() => this.handleOperation("choose", record)}/>
                            </div>
                        );
                    }
                }
            }];

        const formList = [
            {
                type: 'INPUT',
                label: "设备名称",
                field: 'equiName',
            }, !propsType2 && !propsType3 ? {
                type: 'INPUTSELECT',
                label: "所属区域",
                field: 'areaId',
                visible: 'area'
            } : {}, !propsType3 ? {
                type: 'INPUTSELECT',
                label: "所属单元",
                field: 'equiUnit',
                visible: 'unit'
            } : {}, {
                type: 'SELECT',
                label: "设备类型",
                field: 'equiTypeId',
                list: (this.state.equipType || []).map((item) => {
                    // console.log(this.state, item);
                    return {id: item.id, name: item.equiTypeName}
                })
            }, {
                type: 'SELECT',
                label: "状态",
                field: 'status',
                list: [
                    {id: 1, name: "启用"},
                    {id: 0, name: "停用"},
                ]
            }
        ];

        return (
            <div className="equipment">
                <BasePage formList={formList} head={head} columns={columns}
                          dataSource={this.state.dataSource} isClear={isClear}
                          updateClearFlag={() => this.setState({isClear: false})}
                          getList={(res) => this.resetList(res)}
                          pagination={this.state.pagination}
                          rowSelection={propsType1 ? "null" : "checkbox"}
                          updateSelectedItem={(selectedRowKeys, record, selectedIds) => {
                              // console.log(selectedRowKeys, record, selectedIds);
                              this.setState({selectedRowKeys, record, selectedIds})
                          }}/>
                <BaseModal
                    width={600}
                    title={type === "add" ? "新增设备" : type === "change" ? "修改设备" : "查看设备"}
                    visible={this.state.equipVisible}
                    onCancel={this.onCancel}
                    footer={null}
                    content={<Form ref={this.form} onFinish={() => this.handleOk()} scrollToFirstError
                                   fields={
                                       [
                                           {"name": "equiId", "value": equipItem.equiId || ''},
                                           {"name": "equiName", "value": equipItem.equiName || ''},
                                           {"name": "areaId", "value": equipItem.areaName || ""},
                                           {"name": "equiUnit", "value": equipItem.equiUnit || ""},
                                           {"name": "intervalTime", "value": equipItem.intervalTime || ''},
                                           {"name": "equiTypeId", "value": equipItem.equiTypeId || ''},
                                           {"name": "equiLocation", "value": equipItem.equiLocation || ''},
                                           {"name": "monitorSite", "value": equipItem.monitorSite || ''},
                                           {"name": "layoutDetection", "value": equipItem.layoutDetection || ''},
                                           {"name": "status", "value": equipItem.status || 1},
                                           {"name": "remark", "value": equipItem.remark || ''}
                                       ]
                                   }>
                        <FormItem label="所属区域"  {...formItemLayout} name='areaId'
                                  rules={[{required: true, message: '请选择所属区域！'}]}>
                            <Input disabled={disabled || propsType2 || propsType3}
                                   onClick={() => this.setState({areaVisible: true})}/>
                        </FormItem>
                        <FormItem label="所属单元"  {...formItemLayout} name='equiUnit'
                                  rules={[{required: true, message: '请选择所属单元！'}]}>
                            <Input disabled={disabled || propsType3}
                                   onClick={() => {
                                       if (equipItem.areaId) {
                                           this.setState({unitVisible: true});
                                       } else {
                                           message.error("请先选择区域");
                                       }
                                   }}/>
                        </FormItem>
                        <FormItem label="设备id" {...formItemLayout} name='equiId'
                                  rules={[{required: true, message: '请输入设备id！'}, {
                                      pattern: /^[0-9]+$/,
                                      message: '设备id格式错误！'
                                  }]}>
                            <Input placeholder="" disabled={disabled}/>
                        </FormItem>
                        <FormItem label="设备名称" {...formItemLayout} name='equiName'
                                  rules={[{required: true, message: '请输入设备名称！'}]}>
                            <Input placeholder="" disabled={disabled}/>
                        </FormItem>
                         <FormItem label="设备位置信息" name="equiLocation"{...formItemLayout}
                                  rules={[{required: true, message: '请输入设备位置信息！'}]}>
                            <Input disabled={disabled}/>
                        </FormItem> 
                        <FormItem label="检测部位" name="monitorSite"{...formItemLayout}
                                  rules={[{required: true, message: '请输入检测部位！'}]}>
                            <Input disabled={disabled}/>
                        </FormItem>
                        <FormItem label="布设方向" name="layoutDetection"{...formItemLayout}
                                  rules={[{required: true, message: '请输入布设方向！'}]}>
                            <Input disabled={disabled}/>
                        </FormItem>
                        <FormItem label="设备类型"  {...formItemLayout} name='equiTypeId'
                                  rules={[{required: true, message: '请选择设备类型！'}]}>
                            <Select disabled={disabled}>
                                {equipType.map(item => {
                                    return <Option key={item.id} value={item.id}>{item.equiTypeName}</Option>;
                                })}
                            </Select>
                        </FormItem>
                        <FormItem label="数据发送时间间隔"  {...formItemLayout} name='intervalTime'
                                  rules={[{required: true, message: '请输入数据发送时间间隔！'}, {
                                      pattern: /^[0-9]{1,5}$/,
                                      message: '数据发送时间间隔格式错误！'
                                  }]}>
                            <Input disabled={disabled}/>
                        </FormItem>
                        <FormItem label="状态" name="status"{...formItemLayout}>
                            <Select disabled={disabled}>
                                <Option key={1} value={1}>开启</Option>
                                <Option key={0} value={0}>关闭</Option>
                            </Select>
                        </FormItem>
                        <FormItem label="备注" name='remark'{...formItemLayout}>
                            <Input.TextArea type="text" autosize={{minRows: 4, maxRows: 6}}
                                            placeholder="请输入备注" disabled={disabled}/>
                        </FormItem>
                        <Form.Item>
                            {type === "view" ?
                                <Row>
                                    <Col span={24} style={{textAlign: 'center'}}>
                                        <Button type="primary" onClick={this.onCancel}>
                                            确定
                                        </Button>
                                    </Col>
                                </Row> :
                                <Row>
                                    <Col span={12} style={{textAlign: 'right'}}>
                                        <Button type="primary" onClick={this.onCancel}>
                                            取消
                                        </Button>
                                    </Col>
                                    <Col span={12} style={{textAlign: 'left', paddingLeft: 30}}>
                                        <Button type="primary" htmlType="submit">
                                            提交
                                        </Button>
                                    </Col>
                                </Row>
                            }
                        </Form.Item>
                    </Form>}/>
                <BaseModal
                    width={1200}
                    title={"选择区域"}
                    visible={this.state.areaVisible}
                    onCancel={() => this.setState({areaVisible: false})}
                    footer={null}
                    content={<AreaManage propsType="equipment" handleClick={(res) => {
                        // console.log(res);

                        this.setState({
                            equipItem: {
                                ...equipItem,
                                areaId: res.areaId,
                                areaName: res.areaName,
                                areaPort: res.areaPort
                            },
                            areaVisible: false
                        });

                        if (template) {
                            window.open(url + DOWNLOAD_EQUIPMENT + "?area_id=" + res.areaId, "_parent");
                            this.setState({template: false})
                            this.onCancel();
                        }
                    }}/>}/>
                <BaseModal
                    width={1000}
                    title={"选择单元"}
                    visible={this.state.unitVisible}
                    onCancel={() => this.setState({unitVisible: false})}
                    footer={null}
                    content={<UnitManage propsType="select" unitParam={equipItem} handleClick={(res) => {
                        // console.log(res);
                        this.setState({
                            equipItem: {...equipItem, equiUnit: res.equiUnit, unitName: res.unitName},
                            unitVisible: false
                        })
                    }}/>}/>
            </div>
        );
    }
}
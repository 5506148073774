import React, {Component} from 'react';
import {Button, Checkbox, Col, Form, Input, message, Modal, Row} from "antd";
import axios from "../../axios";
import {LockOutlined, VerifiedOutlined, UserOutlined} from '@ant-design/icons';
import Constant from "../../utils/constant";
import './styles.less';

const FormItem = Form.Item;

export default class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",

            isLoading: false,
            timer: 60,
        };
        this.form = React.createRef();
        this.form1 = React.createRef();
    }

    componentWillUnmount() {
        //清除计时器
        this.state.siv && clearInterval(this.state.siv);
    }

    //发送校验码
    handleSendMsg = (data) => {
        let email = data.email;
        let reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if (!email) {
            message.error("用户邮箱为空，请联系管理员修改");
        } else if (!reg.test(email)) {
            message.error("用户邮箱格式不正确，请联系管理员修改");
        } else {
            this.loading();

            let params = {toEmail: email};

            axios.PostAjax({
                url: '/user/send/email',
                data: {
                    params
                }
            }).then(res => {
                    message.info(res.msg);
                    this.setState({
                        isLoading: true
                    });

                    this.setState({code: data})
                }
            ).catch(e => console.log(e));
        }
    };

    //等待
    loading = () => {
        let {timer} = this.state;
        let siv = setInterval(() => {
            this.setState({timer: (timer--), isLoading: true, siv}, () => {
                if (timer === 0) {
                    clearInterval(siv);
                    this.setState({isLoading: false, timer: 60})
                }
            });
        }, 1000);
    };

    //根据用户名获取用户信息
    getMessage = () => {
        if (this.state.username === "") {
            message.error("用户名不能为空");
        } else {
            axios.PostAjax({
                url: "/user/getByUsername",
                data: {
                    params: {username: this.state.username}
                }
            }).then(res => {
                if (res.msg === "获取成功") {
                    if (res.data === null) {
                        message.error("用户不存在");
                    } else {
                        this.handleSendMsg(res.data);
                    }
                }
            });
        }
    };

    //提交登录信息
    handleSubmit = () => {
        // console.log(this.form.current.getFieldsValue());
        let userInfo = this.form.current.getFieldsValue();
        this.form.current.validateFields().then(values => {
            this.props.loginSubmit({
                userCode: userInfo.userCode,
                password: userInfo.password
            });
        }).catch(res => {
            console.log(res);
        });
    };

    //姓名校验
    checkUsername = (rule, value, callback) => {
        var reg = /^[A-Za-z0-9]+$/;//匹配由数字和26个英文字母组成的字符串
        if (!value) {
            callback('用户名不能为空');
        } else if (!reg.test(value)) {
            callback('用户名只允许输入数字和英文字母');
        } else {
            callback();
        }
    };

    //重置密码
    handleForget = () => {
        console.log(this.form1.current.getFieldsValue());
        let data = this.form1.current.getFieldsValue();
        // console.log(data);
        if (!data.username) {
            message.error("用户名不能为空")
        } else if (!data.code) {
            message.error("验证码不能为空")
        } else if (data.code !== this.state.code) {
            message.error("验证码不匹配")
        } else if (!data.password) {
            message.error("新密码不能为空")
        } else {
            let params = {
                username: data.username,
                password: data.password
            };
            axios.PostAjax({
                url: '/user/forget/pwd',
                data: {
                    params
                }
            }).then(res => {
                    message.info(res.msg);
                    if (res.msg === "重置密码成功，请牢记此密码") {
                        this.form1.current.resetFields();
                        this.setState({
                            forgetVisible: false
                        })
                    }
                }
            ).catch(e => console.log(e))
        }
    };

    //记住密码勾选
    handleChange = (event) => {
        const target = event.target;
        this.props.handleChecked(target.checked === true ? 1 : 2);
    };

    render() {
        const formItemLayout = {labelCol: {span: 6}, wrapperCol: {span: 16}};

        // console.log(localStorage.getItem(Constant.NAMESPANCE_NAME));
        let name = localStorage.getItem(Constant.NAMESPANCE_NAME) ? localStorage.getItem(Constant.NAMESPANCE_NAME) : "";
        let password = localStorage.getItem(Constant.NAMESPANCE_PASS) ? localStorage.getItem(Constant.NAMESPANCE_PASS) : "";
        let status = localStorage.getItem(Constant.NAMESPANCE_STATUS) ? localStorage.getItem(Constant.NAMESPANCE_STATUS) === "true" : true;
        // console.log(name, password, status);

        return (
            <div className="login-form">
                <div className="login-title"><h1>账号登录</h1></div>
                <Form ref={this.form}
                      initialValues={{userCode: name, password: password, remember: status}}>
                    <FormItem name='userCode' rules={[{validator: this.checkUsername}]}>
                        <Input prefix={<UserOutlined/>} placeholder="请输入用户名"/>
                    </FormItem>
                    <FormItem name='password' rules={[{required: true, message: '密码不能为空'}]}>
                        <Input prefix={<LockOutlined/>} type="password" placeholder="请输入密码"/>
                    </FormItem>
                    <Row>
                        <Col span={12}>
                            <FormItem name='remember' valuePropName='checked'>
                                <Checkbox onChange={this.handleChange}>记住密码</Checkbox>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            {/*<a style={{float: 'right'}} onClick={() => this.setState({forgetVisible: true})}>忘记密码</a>*/}
                            <a style={{float: 'right'}}>忘记密码</a>
                        </Col>
                    </Row>
                    <FormItem>
                        <Button className="login-form-button" type="primary" onClick={this.handleSubmit}>登录</Button>
                    </FormItem>
                </Form>
                <div className="login-tip">
                    <p>
                        温馨提示:<br/>
                        1.用户名为教师工号或学生学号,初始密码为教师工号或学生学号<br/>
                        2.推荐使用谷歌浏览器 <br/>
                    </p>
                </div>
                <Modal title="忘记密码"
                       width={450}
                       visible={this.state.forgetVisible}
                       onOk={this.handleForget}
                       okText={"重置密码"}
                       cancelText={'取消'}
                       destroyOnClose={true}//关闭后销毁
                       maskClosable={false}//点击蒙层是否允许关闭
                       keyboard={false}//是否支持键盘 esc 关闭
                       onCancel={() => {
                           console.log(this.form1.current.getFieldsValue());
                           this.form1.current.resetFields();
                           this.setState({
                               forgetVisible: false
                           })
                       }}>
                    <Form ref={this.form1}>
                        <FormItem label="用户名" {...formItemLayout} name='username' rules={[{required: true}]}>
                            <Input prefix={<UserOutlined/>} type="text" placeholder="请输入用户名"
                                   onChange={e => this.setState({username: e.target.value})}/>
                        </FormItem>
                        <FormItem label="验证码" {...formItemLayout} name='code'
                                  rules={[{required: true, message: "验证码不能为空"}]}>
                            <div>
                                <Input prefix={<VerifiedOutlined/>} type="text" placeholder="请输入验证码"
                                       style={{width: "70%", float: 'left'}}/>
                                <Button style={{width: "30%", float: 'right', padding: 0}} type="primary"
                                        disabled={this.state.isLoading}
                                        loading={this.state.isLoading}
                                        onClick={this.getMessage}>
                                    {this.state.isLoading ? this.state.timer + "s" : "获取验证码"}
                                </Button>
                            </div>
                        </FormItem>
                        <FormItem label="新密码" {...formItemLayout} name='password'
                                  rules={[{required: true, message: "新密码不能为空"}]}>
                            <Input prefix={<LockOutlined/>} type="password" placeholder="请输入新密码"/>
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        );
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import {ConfigProvider} from "antd";
import zhCN from 'antd/es/locale/zh_CN';
import reportWebVitals from './reportWebVitals';
import Router from "./router";
import store from "./redux/store";

ReactDOM.render((
    <Provider store={store}>
        <ConfigProvider locale={zhCN}>
            <Router/>
        </ConfigProvider>
    </Provider>
), document.getElementById('root'));

reportWebVitals();

import React, {Component} from "react";
import BasePage from "../../../component/BasePage";
import {Button, Form, message, Modal, Select, Checkbox} from "antd";
import BaseTool from "../../../component/BaseTool";
import BaseModal from "../../../component/BaseModal";
import axios from "../../../axios";
import './styles.less';
import {
    AREA_LIST, USER_AREA_ADD, USER_AREA_DELETE
} from "../../../axios/url";
import AreaManage from "../areaManage";
import {pageSize} from "../../../utils";
import Utils from "../../../utils";

const confirm = Modal.confirm;

const formList = [
    {
        type: 'INPUT',
        label: "区域名称",
        field: 'areaName',
    }, {
        type: 'INPUT',
        label: "区域ip",
        field: 'areaIp',
    }, {
        type: 'INPUT',
        label: "区域端口",
        field: 'areaPort',
    }
];

const formItemLayout = {labelCol: {span: 6}, wrapperCol: {span: 16}};

export default class UserArea extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],

            checkedList: [],
            options: [],

            isClear: false
        };

        this.params = {pageNo: 1};
        this.params1 = {};//筛选参数
    }

    componentDidMount() {
        this.getList();
    }

    getList = () => {
        axios.PostAjax({
            url: AREA_LIST,
            data: {
                params: {
                    ...this.params1, current: this.params.pageNo, size: pageSize, userCode: this.props.userItem.userCode
                }
            }
        }).then(res => {
            // console.log(res);
            this.setState({
                isClear: true,
                dataSource: (res.DATA.areaList || []).map((item, index) => {
                    item.key = pageSize * (this.params.pageNo - 1) + index + 1;
                    return item;
                }),
                pagination: Utils.pagination(res.DATA, (current) => {
                    this.params.pageNo = current;//当前页数
                    this.getList(); //刷新列表数据
                })
            })
        });
    };

    handleOperation = (type, record) => {
        switch (type) {
            case "delete":
                this.handleDelete(type, record);
                break;
            case "add":
                this.setState({
                    areaVisible: true
                });
                break;
        }

        this.setState({type});
    };

    resetList = (res) => {
        if (res) this.params1 = res;
        this.params.pageNo = 1;
        this.getList();
    };

    handleDelete = (type, item) => {
        confirm({
            title: '确认删除?',
            okText: '是',
            okType: 'danger',
            cancelText: '否',
            onOk: () => {
                axios.PostAjax({
                    url: USER_AREA_DELETE,
                    data: {
                        params: {
                            userCode: this.props.userItem.userCode,
                            areaId: item.areaId
                        }
                    }
                }).then((res) => {
                    message.success(res.DATA);
                    this.resetList();
                })
            }
        });

    };

    handleUserArea = (item) => {
        axios.PostAjax({
            url: USER_AREA_ADD,
            data: {
                params: {
                    userCode: this.props.userItem.userCode,
                    areaId: item
                }
            }
        }).then((res) => {
            message.success(res.DATA);
            this.setState({
                areaVisible: false,
            }, () => this.resetList());
        })
    };

    render() {

        const {isClear} = this.state;

        let head = <div style={{
            position: "relative",
            float: "right",
            marginBottom: "20px",
            display: this.props.userType === 2 ? "none" : "block"//只对管理员显示
        }}>
            <BaseTool type="headButton" name="添加" onClick={() => this.handleOperation("add")}/>
        </div>;

        const columns = [
            {
                title: '序号',
                dataIndex: 'key'
            }, {
                title: '区域名称',
                dataIndex: 'areaName'
            }, {
                title: '区域ip',
                dataIndex: 'areaIp',
            }, {
                title: '区域端口',
                dataIndex: 'areaPort',
            }, {
                title: '区域地址',
                dataIndex: 'areaLocation',
                render: (text, item) => {
                    return item.areaLocation.replace(/,/g, "") + item.areaLocationAddress;
                }
            }, {
                title: '操作',
                dataIndex: 'operation',
                render: (text, record) => {
                    return (
                        <div>
                            <BaseTool type="button" name="删除" onClick={() => this.handleOperation("delete", record)}/>
                        </div>
                    )
                }
            }];

        return (
            <div className="userArea">
                <BasePage formList={formList} head={head} columns={columns}
                          dataSource={this.state.dataSource} isClear={isClear}
                          getList={(res) => this.resetList(res)}
                          pagination={this.state.pagination}
                          updateClearFlag={() => this.setState({isClear: false})}
                          updateSelectedItem={(selectedRowKeys, record, selectedIds) => {
                              // console.log(selectedRowKeys, record, selectedIds);
                              this.setState({selectedRowKeys, record, selectedIds})
                          }}/>
                <BaseModal
                    width={1200}
                    title="选择区域"
                    visible={this.state.areaVisible}
                    onCancel={() => this.setState({areaVisible: false})}
                    footer={null}
                    content={<AreaManage propsType="select" handleClick={(res) => {
                        // console.log(res);
                        this.handleUserArea(res.areaId);
                    }}/>}/>
            </div>
        );
    }
}
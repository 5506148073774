import React, {Component} from 'react';
import BasePage from "../../../component/BasePage";
import {Button, Col, Form, Input, message, Modal, Row, Select} from "antd";
import BaseTool from "../../../component/BaseTool";
import BaseModal from "../../../component/BaseModal";
import axios from '../../../axios'
import {UNIT_ADD, UNIT_UPDATE, UNIT_DELETE, UNIT_LIST} from "../../../axios/url";
import AreaManage from "../areaManage";
import './styles.less';
import Utils, {pageSize} from "../../../utils";
import EquipmentManage from "../equipmentManage";

const FormItem = Form.Item;
const Option = Select.Option;
const confirm = Modal.confirm;

const formItemLayout = {labelCol: {span: 6}, wrapperCol: {span: 16}};

export default class UnitManage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            unitItem: props.propsType === "area" ? {...props.areaItem} : {},

            unitType: [],

            isClear: false
        };
        this.form = React.createRef();

        this.params = {pageNo: 1};
        this.params1 = {};//筛选参数
    }

    componentDidMount() {
        this.getList();
    }

    getList = () => {
        let {propsType, areaItem, unitParam} = this.props;
        let data = propsType === "area" ?
            {...this.params1, areaId: areaItem.areaId} : propsType === "select" ? {
                ...this.params1, areaId: (unitParam || {}).areaId
            } : {...this.params1};

        axios.PostAjax({
            url: UNIT_LIST,
            data: {
                params: {
                    ...data, current: this.params.pageNo, size: pageSize
                }
            }
        }).then(res => {
            this.setState({
                isClear: true,
                dataSource: (res.DATA.areaUnitList || []).map((item, index) => {
                    item.key = pageSize * (this.params.pageNo - 1) + index + 1;
                    return item;
                }),
                pagination: Utils.pagination(res.DATA, (current) => {
                    this.params.pageNo = current;//当前页数
                    this.getList(); //刷新列表数据
                })
            })
        });
    };


    handleOperation = (type, record) => {
        switch (type) {
            case "view":
                this.setState({
                    unitItem: record,
                    unitVisible: true
                });
                break;
            case "change":
                this.setState({
                    unitItem: record,
                    unitVisible: true
                });
                break;
            case "add":
                this.setState({unitVisible: true});
                break;
            case "delete":
                this.handleDelete(type, record);
                break;
            case "deleteAll":
                this.handleDelete(type, record);
                break;
            case "equip":
                this.setState({
                    unitItem: record,
                    equipVisible: true
                });
                break;
            case "choose":
                this.props.handleClick(record);
                break;
        }

        this.setState({type});
    };

    resetList = (res) => {
        if (res) this.params1 = res;
        this.params.pageNo = 1;
        this.getList();
    };

    handleOk = (type) => {
        let {propsType, areaItem} = this.props;
        let areaId = propsType === "area" ? areaItem.areaId : this.state.unitItem.areaId;
        let data = this.form.current.getFieldsValue();
        // console.log(data);
        // console.log(this.state);

        axios.PostAjax({
            url: this.state.type === "add" ? UNIT_ADD : UNIT_UPDATE,
            data: {
                params: this.state.type === "add" ? {
                    ...data, areaId
                } : {
                    ...data, areaId, id: this.state.unitItem.id
                }
            }
        }).then((res) => {
            message.success(res.DATA);
            this.onCancel();
            this.resetList();
        });
    };

    handleDelete = (type, item) => {
        let count = (this.state.selectedIds || []).length;
        if (type === "delete" || (type === "deleteAll" && count > 0)) {
            confirm({
                title: type === "delete" ? '确认删除?' : '确认删除' + count + '项?',
                okText: '是',
                okType: 'danger',
                cancelText: '否',
                onOk: () => {
                    axios.ajax({
                        url: UNIT_DELETE,
                        method: "delete",
                        data: {
                            params: type === "delete" ? {idList: item.id} : {idList: this.state.selectedIds.toString()}
                        }
                    }).then((res) => {
                        message.success(res.DATA);
                        this.getList();
                    })
                }
            });
        } else if (count === 0) {
            message.info("请选择单元");
        }
    };

    onCancel = () => {
        if (this.form.current) this.form.current.resetFields();
        this.setState({unitVisible: false, unitItem: {}});
    };

    render() {
        let {type, unitItem, isClear} = this.state;
        let {areaItem, propsType} = this.props;
        let disabled = type === "view";

        let propsType1 = propsType === "equipment" || propsType === "select";
        propsType = propsType === "area";//areaManagement

        // console.log(this.state);
        // console.log(propsType);

        let head = propsType1 ? null :
            <div style={{
                position: "relative",
                float: "right",
                marginBottom: "20px",
                display: this.props.userType === 2 ? "none" : "block"//只对管理员显示
            }}>
                <BaseTool type="headButton" name="删除单元" onClick={() => this.handleOperation("deleteAll")}/>
                <BaseTool type="headButton" name="新增单元" onClick={() => this.handleOperation("add")}/>
            </div>;

        const columns = [
            {
                title: '序号',
                dataIndex: 'key'
            }, {
                title: '所属区域',
                dataIndex: 'areaName',
            }, {
                title: '设备单元号',
                dataIndex: 'equiUnit'
            }, {
                title: '操作时间',
                dataIndex: 'operationTime',
                render: Utils.formatDate
            }, {
                title: '操作',
                dataIndex: 'operation',
                render: (text, record) => {
                    if (!propsType1) {
                        return (
                            <div>
                                <BaseTool type="button" name="查看详情"
                                          onClick={() => this.handleOperation("view", record)}/>
                                {/*<BaseTool type="button" name="修改信息"*/}
                                {/*onClick={() => this.handleOperation("change", record)}/>*/}
                                <BaseTool type="button" name="设备管理"
                                          onClick={() => this.handleOperation("equip", record)}/>
                                <BaseTool type="button" name="删除"
                                          onClick={() => this.handleOperation("delete", record)}/>
                            </div>
                        );
                    } else {
                        return (
                            <div>
                                <BaseTool type="button" name="查看详情"
                                          onClick={() => this.handleOperation("view", record)}/>
                                <BaseTool type="button" name="选择"
                                          onClick={() => this.handleOperation("choose", record)}/>
                            </div>
                        );
                    }
                }
            }];

        const formList = [
            {
                type: 'INPUT',
                label: "设备单元号",
                field: 'equiUnit',
            }, {
                type: 'INPUTSELECT',
                label: "所属区域",
                field: 'areaId',
                visible: 'area'
            }
        ];

        return (
            <div className="unit">
                <BasePage formList={formList} head={head} columns={columns}
                          dataSource={this.state.dataSource} isClear={isClear}
                          updateClearFlag={() => this.setState({isClear: false})}
                          getList={(res) => this.resetList(res)}
                          pagination={this.state.pagination}
                          rowSelection={propsType1 ? "null" : "checkbox"}
                          updateSelectedItem={(selectedRowKeys, record, selectedIds) => {
                              // console.log(selectedRowKeys, record, selectedIds);
                              this.setState({selectedRowKeys, record, selectedIds})
                          }}/>
                <BaseModal
                    width={600}
                    title={type === "add" ? "新增单元" : type === "change" ? "修改单元" : "查看单元"}
                    visible={this.state.unitVisible}
                    onCancel={this.onCancel}
                    footer={null}
                    content={<Form ref={this.form} onFinish={() => this.handleOk()} scrollToFirstError
                                   fields={
                                       [
                                           {"name": "equiUnit", "value": unitItem.equiUnit || ''},
                                           {"name": "equiName", "value": unitItem.equiName || ''},
                                           {"name": "areaName", "value": unitItem.areaName || ""},
                                           {"name": "status", "value": unitItem.status || 1},
                                           {"name": "remark", "value": unitItem.remark || ''}
                                       ]
                                   }>
                        <FormItem label="所属区域"  {...formItemLayout} name='areaName'
                                  rules={[{required: true, message: '请选择所属区域！'}]}>
                            <Input disabled={disabled || propsType}
                                   onClick={() => this.setState({areaVisible: true})}/>
                        </FormItem>
                        <FormItem label="设备单元号" {...formItemLayout} name='equiUnit'
                                  rules={[{required: true, message: '请输入设备单元号！'}]}>
                            <Input placeholder="" disabled={disabled}/>
                        </FormItem>
                        {/*<FormItem label="单元名称" {...formItemLayout} name='equiName'*/}
                        {/*rules={[{required: true, message: '请输入单元名称！'}]}>*/}
                        {/*<Input placeholder="" disabled={disabled}/>*/}
                        {/*</FormItem>*/}
                        {/*<FormItem label="状态" name="status"{...formItemLayout}>*/}
                        {/*<Select disabled={disabled}>*/}
                        {/*<Option key={1} value={1}>开启</Option>*/}
                        {/*<Option key={0} value={0}>关闭</Option>*/}
                        {/*</Select>*/}
                        {/*</FormItem>*/}
                        {/*<FormItem label="备注" name='remark'{...formItemLayout}>*/}
                        {/*<Input.TextArea type="text" autosize={{minRows: 4, maxRows: 6}}*/}
                        {/*placeholder="请输入备注" disabled={disabled}/>*/}
                        {/*</FormItem>*/}
                        <Form.Item>
                            {type === "view" ?
                                <Row>
                                    <Col span={24} style={{textAlign: 'center'}}>
                                        <Button type="primary" onClick={this.onCancel}>
                                            确定
                                        </Button>
                                    </Col>
                                </Row> :
                                <Row>
                                    <Col span={12} style={{textAlign: 'right'}}>
                                        <Button type="primary" onClick={this.onCancel}>
                                            取消
                                        </Button>
                                    </Col>
                                    <Col span={12} style={{textAlign: 'left', paddingLeft: 30}}>
                                        <Button type="primary" htmlType="submit">
                                            提交
                                        </Button>
                                    </Col>
                                </Row>
                            }
                        </Form.Item>
                    </Form>}/>
                <BaseModal
                    width={1200}
                    title={"选择区域"}
                    visible={this.state.areaVisible}
                    onCancel={() => this.setState({areaVisible: false})}
                    footer={null}
                    content={<AreaManage propsType="unit" handleClick={(res) => {
                        // console.log(res);
                        this.setState({
                            unitItem: {...unitItem, areaId: res.areaId, areaName: res.areaName},
                            areaVisible: false
                        })
                    }}/>}/>
                <BaseModal
                    width={1200}
                    title="设备管理"
                    visible={this.state.equipVisible}
                    onCancel={() => {
                        this.setState({equipVisible: false})
                    }}
                    footer={null}
                    content={<EquipmentManage propsType="unit" unitItem={unitItem}/>}/>
            </div>
        );
    }
}
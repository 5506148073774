import React, {Component} from 'react';
import {RECEIVE_RECORD_LIST, USER_DELETE} from "../../../axios/url";
import BaseTool from "../../../component/BaseTool";
import BasePage from "../../../component/BasePage";
import axios from "../../../axios";
import Utils, {pageSize} from "../../../utils";
import {Checkbox, Form, message, Modal, Select} from "antd";
import BaseModal from "../../../component/BaseModal";
import Data from "./data";
import './styles.less';

const FormItem = Form.Item;
const Option = Select.Option;
const confirm = Modal.confirm;
const CheckboxGroup = Checkbox.Group;


const formList = [
    {
        type: 'INPUTSELECT',
        label: "区域",
        field: 'areaId',
        visible: "area"
    }, {
        type: 'INPUT',
        label: "单元",
        field: 'equiUnit',
    }, {
        type: 'INPUTSELECT',
        label: "设备",
        field: 'equiId',
        visible: "equip"
    }, {
        type: 'RANGE',
        label: "起止时间",
        field: 'time',
    }
];

export default class DataDisplay extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],

            isClear: false
        };

        this.params = {pageNo: 1};
        this.params1 = {};//筛选参数
    }

    componentDidMount() {
        this.getList();
    }

    getList = () => {
        // console.log(this.params1);
        if (this.params1.time) {
            this.params1.startTime = this.params1.time[0];
            this.params1.endTime = this.params1.time[1];
        }
        axios.PostAjax({
            url: RECEIVE_RECORD_LIST,
            data: {
                params: {...this.params1, current: this.params.pageNo, size: pageSize}
            }
        }).then(res => {
            // console.log(res);
            this.setState({
                isClear: true,
                dataSource: (res.DATA.receiveRecordList || []).map((item, index) => {
                    item.key = pageSize * (this.params.pageNo - 1) + index + 1;
                    return item;
                }),
                pagination: Utils.pagination(res.DATA, (current) => {
                    this.params.pageNo = current;//当前页数
                    this.getList(); //刷新列表数据
                })
            })
        });
    };

    handleOperation = (type, record) => {
        switch (type) {
            case "view":
                this.setState({
                    item: record,
                    visible: true
                });
                break;
            case "delete":
                this.handleDelete(type, record);
                break;
            case "deleteAll":
                this.handleDelete(type, record);
                break;
        }

        this.setState({type});
    };

    resetList = (res) => {
        if (res) this.params1 = res;
        this.params.pageNo = 1;
        this.getList();
    };

    handleDelete = (type, item) => {
        // console.log(this.state.selectedItem, this.state.record, this.state.selectedIds);
        let count = (this.state.selectedIds || []).length;
        // console.log(this.state.selectedIds);
        // console.log(this.state.selectedIds.toString());
        if (type === "delete" || (type === "deleteAll" && count > 0)) {
            confirm({
                title: type === "delete" ? '确认删除?' : '确认删除' + count + '项?',
                okText: '是',
                okType: 'danger',
                cancelText: '否',
                onOk: () => {
                    axios.ajax({
                        url: USER_DELETE,
                        method: "delete",
                        data: {
                            params: type === "delete" ? {idList: item.id} : {idList: this.state.selectedIds.toString()}
                        }
                    }).then((res) => {
                        message.success(res.DATA);
                        this.resetList();
                    })
                }
            });
        } else if (count === 0) {
            message.info("请选择用户");
        }
    };

    onCancel = () => {
        this.setState({visible: false, item: {}})
    };

    render() {

        let {item, visible, isClear} = this.state;

        let head = <div style={{
            position: "relative",
            float: "right",
            marginBottom: "20px",
            display: this.props.userType === 2 ? "none" : "block"//只对管理员显示
        }}>
            {/*<BaseTool type="headButton" name="删除数据" onClick={() => this.handleOperation("deleteAll")}/>*/}
            {/*<BaseTool type="headButton" name="数据对比" onClick={() => this.handleOperation("compare")}/>*/}
        </div>;

        const columns = [
            {
                title: '序号',
                dataIndex: 'key',
                width: 50
            }, {
                title: '区域',
                dataIndex: 'areaName'
            },
            // {
            //     title: '区域位置',
            //     dataIndex: 'areaLocation'
            // },
            {
                title: '单元',
                dataIndex: 'equiUnit'
            }, {
                title: '设备',
                dataIndex: 'equiId'
            }, {
                title: '设备名称',
                dataIndex: 'equiName'
            }, {
                title: '数据类型',
                dataIndex: 'receiveType'
            }, {
                title: '温度',
                dataIndex: 'temperature'
            }, {
                title: 'Cl⁻浓度',
                dataIndex: 'clConcentration'
            }, {
                title: 'PH值',
                dataIndex: 'ph'
            }, {
                title: '数据',
                dataIndex: 'receiveData',
                ellipsis: true,
                width: 250
            }, {
                title: '接收时间',
                dataIndex: 'createTime',
                render: text => {
                    return Utils.formatDate(text);
                }
            }, {
                title: '操作',
                dataIndex: 'operation',
                render: (text, record) => {
                    return (
                        <div>
                            <BaseTool type="button" name="查看数据" style={{marginLeft: 0}}
                                      onClick={() => this.handleOperation("view", record)}/>
                            {/*<BaseTool type="button" name="删除" onClick={() => this.handleOperation("delete", record)}/>*/}
                        </div>
                    )
                }
            }];

        return (
            <div className="data">
                <BasePage formList={formList} head={head} columns={columns}
                          dataSource={this.state.dataSource} isClear={isClear}
                          updateClearFlag={() => this.setState({isClear: false})}
                          getList={(res) => this.resetList(res)}
                          pagination={this.state.pagination}
                          updateSelectedItem={(selectedRowKeys, record, selectedIds) => {
                              // console.log(selectedRowKeys, record, selectedIds);
                              this.setState({selectedRowKeys, record, selectedIds})
                          }}/>
                <BaseModal
                    width={800}
                    title={"查看数据"}
                    visible={visible}
                    onCancel={this.onCancel}
                    footer={null}
                    content={
                        <Data item={item}/>
                    }
                />
            </div>
        );
    }
}
import React from 'react';
import {Col, Row, message} from "antd";
import {MenuUnfoldOutlined, MenuFoldOutlined} from "@ant-design/icons";
import './header.less';
import UserOutlined from "@ant-design/icons/es/icons/UserOutlined";
import BarsOutlined from "@ant-design/icons/es/icons/BarsOutlined";
import PieChartOutlined from "@ant-design/icons/es/icons/PieChartOutlined";
import SolutionOutlined from "@ant-design/icons/es/icons/SolutionOutlined";
import {connect} from 'react-redux';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: props.userInfo || {}
        };
    }

    handleClick = (flag) => {
        // if (flag === "1" || flag === "2" || flag === "3") {
        this.props.dispatchActiveKey(flag);
        // } else if (flag === "4") {
        //     message.info("功能未开放");
        // } else if (flag === "5") {
        //     message.info("功能待开发");
        // } else {
        //
        // }
    };

    setModule = (type, actions, name, color) => {
        switch (type) {
            case 1:
                if (actions.length >= 1) {
                    let name = actions[0].authName;
                    return <Col span={6}>
                        <div className={"module-top"}
                             style={{backgroundColor: "#00B7C9"}}
                             onClick={() => this.handleClick(name)}>
                            <div style={{height: 50}}><BarsOutlined style={{fontSize: 70}}/></div>
                            {name}
                        </div>
                    </Col>
                } else return null;
            case 2:
                if (actions.length === 2) {
                    let name = actions[1].authName;
                    return <Col span={6}>
                        <div className={"module-top"}
                             style={{backgroundColor: "#007AD8"}}
                             onClick={() => this.handleClick(name)}>
                            <div style={{height: 50, marginBottom: -15}}>
                                <PieChartOutlined style={{fontSize: 60}}/>
                            </div>
                            {name}
                        </div>
                    </Col>;
                } else if (actions.length >= 3) {
                    let name1 = actions[1].authName;
                    let name2 = actions[2].authName;
                    return <Col span={6}>
                        <div className={"module-top1"}
                             style={{backgroundColor: "#007AD8"}}
                             onClick={() => this.handleClick(name1)}>
                            <div style={{height: 50, marginBottom: -15}}>
                                <PieChartOutlined style={{fontSize: 60}}/>
                            </div>
                            {name1}
                        </div>
                        <div className={"module-top1"}
                             style={{marginTop: "8px", backgroundColor: "#87A9E3"}}
                             onClick={() => this.handleClick(name2)}>
                            <div style={{height: 50, marginBottom: -15}}>
                                <SolutionOutlined style={{fontSize: 60}}/>
                            </div>
                            {name2}
                        </div>
                    </Col>;
                } else {
                    return null;
                }
            case 3:
                return <Col span={Math.floor(24 / (actions.length > 3 ? actions.length - 3 : 1))}>
                    <div className="module-bottom" style={{backgroundColor: color}}
                         onClick={() => this.handleClick(name)}>
                        <div style={{height: 50, marginBottom: -15}}>
                            <MenuFoldOutlined style={{fontSize: 50}}/>
                        </div>
                        {name}
                    </div>
                </Col>;
        }
    };

    render() {
        let {userInfo, actions} = this.props;
        // let type = userInfo.userType;
        let type = 3;
        // console.log(userInfo);
        // console.log(actions);
        let actions1 = actions.filter(item => item.authName !== "个人中心" && item.authClass === '0');
        // console.log(actions1);

        let colors = ["#007F9B", "#92D04B", "#c5d60f", "#F8AC73", "#87A9E3", "#41D7E3", "#72c1bc"];

        return (
            <div className="home">
                <Row style={type === 3 ? {margin: "0 10px 10px"} : {margin: "30px 10px 10px"}}>
                    <Col span={12}>
                        <div className={type === 3 ? "module-top" : "module-top-teacher"}
                             style={{backgroundColor: "#00639F"}}
                             onClick={() => this.handleClick("个人中心")}>
                            <Row>
                                <Col span={5}/>
                                <Col span={7}>
                                    <div style={{height: 50}}><UserOutlined style={{fontSize: 70}}/></div>
                                    个人信息
                                </Col>
                                <Col span={5}>
                                    <div className={"module-info"}
                                         style={type === 3 ? {paddingTop: 60} : {paddingTop: 90, marginBottom: 30}}>
                                        姓名：{(userInfo || {}).userName}</div>
                                    <div className={"module-info"} style={{marginBottom: -60}}>
                                        账号：{(userInfo || {}).userCode}</div>
                                    <div className={"module-info"}>
                                        身份：{type === 1 ? "普通用户" : "管理员"}
                                    </div>
                                </Col>
                                <Col span={7}/>
                            </Row>
                        </div>
                    </Col>
                    {this.setModule(1, actions1)}
                    {this.setModule(2, actions1)}

                </Row>
                <Row style={{margin: "0 10px", display: type === 3 ? "flex" : "none"}}>
                    {actions1.length >= 3 ? (actions1 || []).slice(3).map((item, i) =>
                        this.setModule(3, actions1, item.authName, colors[i % 7])
                    ) : null}
                    {/*{this.setModule("区域管理", "#007F9B")}*/}
                    {/*{this.setModule("设备管理", "#92D04B")}*/}
                    {/*{this.setModule("设备类型管理", "#c5d60f")}*/}
                    {/*{this.setModule("设备参数", "#F8AC73")}*/}
                    {/*{this.setModule("腐蚀数据", "#87A9E3")}*/}
                    {/*{this.setModule("接收数据", "#41D7E3")}*/}
                    {/*{this.setModule("数据展示", "#72c1bc")}*/}
                    {/*{this.setModule("端口管理", "#007F9B")}*/}
                </Row>
            </div>
        );
    }
}


//把store中的数据映射到这个组件变成props
const mapStateToProps = (state) => {
    // console.log("mapStateToProps:\n", state);
    return {
        actions: state.actions,//所有权限
    };
};

export default connect(mapStateToProps)(Home);
import React, {Component} from 'react';
import {HashRouter, Switch, Route} from "react-router-dom";

import App from './App'
import Login from "./container/login";
import UserManage from "./container/manage/userManage";
import RoleManage from "./container/manage/roleManage";
import AreaManage from "./container/manage/areaManage";
import corrosionData from './container/data/corrosionData'
import equipmentData from './container/data/equipmentData'
import receiveData from './container/data/receiveData'

export default class Router extends Component {

    render() {
        return (
            <HashRouter>
                <Switch>
                    <Route exact path="/" component={App}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/manage/user" component={UserManage}/>
                    <Route path="/manage/role" component={RoleManage}/>
                    <Route path="/manage/area" component={AreaManage}/>
                    <Route path="/data/corrosion" component={corrosionData}/>
                    <Route path="/data/equipment" component={equipmentData}/>
                    <Route path="/data/receive" component={receiveData}/>
                </Switch>
            </HashRouter>
        );
    }
}
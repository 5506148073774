import React, {Component} from 'react'
import {Input, Select, Form, Button, Checkbox, DatePicker, TreeSelect, Row, Col} from 'antd'
import moment from "moment";
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import BaseModal from "../BaseModal";
import AreaManage from "../../container/manage/areaManage";
import EquipmentManage from "../../container/manage/equipmentManage";
import UnitManage from "../../container/manage/unitManage";

const FormItem = Form.Item;
const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;
const {TreeNode} = TreeSelect;
const {RangePicker} = DatePicker;

function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}

function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
}

function disabledDateTime() {
    return {
        disabledHours: () => range(0, 24).splice(4, 20),
        disabledMinutes: () => range(30, 60),
        disabledSeconds: () => [55, 56],
    };
}

function disabledRangeTime(_, type) {
    if (type === 'start') {
        return {
            disabledHours: () => range(0, 60).splice(4, 20),
            disabledMinutes: () => range(30, 60),
            disabledSeconds: () => [55, 56],
        };
    }
    return {
        disabledHours: () => range(0, 60).splice(20, 4),
        disabledMinutes: () => range(0, 31),
        disabledSeconds: () => [55, 56],
    };
}

class FilterForm extends Component {

    form = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            equipParam: {}
        }
    }

    handleFilterSubmit = () => {
        let fieldsValue = this.form.current.getFieldsValue();
        //   console.log(fieldsValue)
        this.props.filterSubmit({...fieldsValue, ...this.state.data});
    };

    renderTreeNodes = (data) => {
        return data.map((item) => {
            if (item.childrenList) {
                return (
                    <TreeNode title={item.name} value={item.id} key={item.id}>
                        {this.renderTreeNodes(item.childrenList)}
                    </TreeNode>
                );
            } else {
                return (
                    <TreeNode title={item.name} value={item.id} key={item.id} isLeaf/>
                );
            }
        });
    };

    renderAreaTreeNodes = (data) => {
        return data.map((item) => {
            if (item.childrenList) {
                return (
                    <TreeNode title={item.name} value={item.id} key={item.id}>
                        {this.renderAreaTreeNodes(item.childrenList)}
                    </TreeNode>
                );
            } else {
                return (
                    <div/>
                );
            }
        });
    };

    reset = () => {
        this.form.current.resetFields();
        this.setState({
            data: {},
            unitParam: {areaId: null},
            equipParam: {}
        })
    };

    // disabledDate = (current) => {
    //     // 不能选今天之后的日期
    //     return current > moment();
    // };

    getOptionList(data) {
        if (!data) {
            return [];
        }
        let options = []; //[<Option value="0" key="all_key">全部</Option>];
        data.map((item) => {
            options.push(<Option value={item.id} key={item.id}>{item.name}</Option>)
        });
        return options;
    }

    getOption(data) {
        if (!data) {
            return [];
        }
        let options = [];
        data.map((item) => {
            options.push(<Checkbox value={item.id} label={item.id}>{item.name}</Checkbox>)
        });
        return options;
    }

    initFormList = () => {
        const formList = this.props.formList;
        const formItemList = [];
        const formItemLayout = {labelCol: {span: 8}, wrapperCol: {span: 16}};
        const formItemLayout1 = {labelCol: {span: 6}, wrapperCol: {span: 16}};

        if (formList && formList.length > 0) {
            formList.forEach((item, index) => {

                let label = item.label;
                let field = item.field;
                let initialValue = item.initialValue || '';
                let placeholder = item.placeholder;
                let width = item.width;
                let visible = item.visible;

                if (item.type === 'TIME') {
                    const begin_time =
                        <FormItem name={'start' + field} label={label} key={'start' + field} style={{marginLeft: 10}}>
                            <DatePicker
                                locale={locale}
                                format="YYYY-MM-DD HH:mm:ss"
                                disabledDate={disabledDate}
                                disabledTime={disabledDateTime}
                                showTime={{format: 'HH:mm:ss', defaultValue: moment('00:00:00', 'HH:mm:ss')}}
                            />
                        </FormItem>;
                    formItemList.push(begin_time);

                    const end_time =
                        <FormItem name={'end' + field} label="~" colon={false} key={'end' + field}>
                            <DatePicker
                                locale={locale}
                                format="YYYY-MM-DD HH:mm:ss"
                                disabledDate={disabledDate}
                                disabledTime={disabledDateTime}
                                showTime={{format: 'HH:mm:ss', defaultValue: moment('00:00:00', 'HH:mm:ss')}}
                            />
                        </FormItem>;
                    formItemList.push(end_time)
                } else if (item.type === 'TIMEDATE') {
                    const begin_time =
                        <FormItem name={'start' + field} label={label} key={'start' + field} style={{marginLeft: 10}}>
                            <DatePicker
                                locale={locale}
                                format="YYYY-MM-DD"
                                disabledDate={disabledDate}
                                disabledTime={disabledDateTime}
                                showTime={{defaultValue: moment('00:00:00', 'HH:mm:ss')}}
                            />
                        </FormItem>;
                    formItemList.push(begin_time);

                    const end_time =
                        <FormItem name={'end' + field} label="~" colon={false} key={'end' + field}>
                            <DatePicker
                                locale={locale}
                                format="YYYY-MM-DD"
                                disabledDate={disabledDate}
                                disabledTime={disabledDateTime}
                                showTime={{defaultValue: moment('00:00:00', 'HH:mm:ss')}}
                            />
                        </FormItem>;
                    formItemList.push(end_time)
                } else if (item.type === 'DATE') {
                    const DATE =
                        <Col span={6}>
                            <FormItem name={field} label={label} key={field} {...formItemLayout}
                                      initialValue={initialValue}>
                                <DatePicker
                                    locale={locale}
                                    format="YYYY-MM-DD"
                                    // disabledDate={disabledDate}
                                    // disabledTime={disabledDateTime}
                                />
                            </FormItem>
                        </Col>;
                    formItemList.push(DATE);
                } else if (item.type === 'RANGE') {
                    const RANGE =
                        <Col span={8}>
                            <FormItem name={field} label={label} key={field} {...formItemLayout1}
                                      initialValue={initialValue}>
                                <RangePicker
                                    locale={locale}
                                    format="YYYY-MM-DD HH:mm"
                                    // disabledDate={disabledDate}
                                    // disabledTime={disabledDateTime}
                                    showTime={{format: 'HH:mm', defaultValue: moment('00:00', 'HH:mm')}}
                                />
                            </FormItem>
                        </Col>;
                    formItemList.push(RANGE);
                } else if (item.type === 'RANGEHEAD') {
                    const RANGEHEAD =
                        <Col span={15}>
                            <FormItem name={field} label={label} key={field} {...formItemLayout1}
                                      initialValue={initialValue}>
                                <RangePicker
                                    locale={locale}
                                    format="YYYY-MM-DD HH:mm"
                                    showTime={{format: 'HH:mm', defaultValue: moment('00:00', 'HH:mm')}}
                                />
                            </FormItem>
                        </Col>;
                    const BUTTON =
                        <Col span={8}>
                            <FormItem>
                                <Button type="primary" style={{margin: '0 20px'}}
                                        onClick={this.handleFilterSubmit}>比较</Button>
                                <Button type="primary" onClick={this.reset}>重置</Button>
                            </FormItem>
                        </Col>;
                    formItemList.push(RANGEHEAD);
                    formItemList.push(BUTTON);
                } else if (item.type === 'INPUT') {
                    const INPUT =
                        <Col span={6} key={field}>
                            <FormItem name={field} label={label} key={field} {...formItemLayout}
                                      initialValue={initialValue}>
                                <Input style={{width: 150}} type="text" placeholder={placeholder}/>
                            </FormItem>
                        </Col>;
                    formItemList.push(INPUT);
                } else if (item.type === 'INPUTSELECT') {
                    const INPUT =
                        <Col span={6} key={field}>
                            <FormItem name={field} label={label} key={field} {...formItemLayout}
                                      initialValue={initialValue}>
                                <Input style={{width: 150}} type="text" placeholder={placeholder}
                                       onClick={() => this.setState({
                                           areaVisible: visible === "area",
                                           unitVisible: visible === "unit",
                                           equipVisible: visible === "equip",
                                       })}/>
                            </FormItem>
                        </Col>;
                    formItemList.push(INPUT);
                } else if (item.type === 'SELECT') {
                    // console.log(item);
                    const SELECT =
                        <Col span={6}>
                            <FormItem name={field} label={label} key={field} {...formItemLayout}
                                      initialValue={initialValue}>
                                <Select
                                    style={{width: 150}}
                                    placeholder={placeholder}>
                                    <Option value={null}>{placeholder}</Option>
                                    {this.getOptionList(item.list)}
                                </Select>
                            </FormItem>
                        </Col>;
                    formItemList.push(SELECT);
                } else if (item.type === 'CHECKBOX') {
                    const CHECKBOX =
                        <FormItem name={[field]} valuePropName={'checked'} label={""} key={field}
                                  initialValue={initialValue}>
                            <Checkbox>
                                {label}
                            </Checkbox>
                        </FormItem>;
                    formItemList.push(CHECKBOX)
                } else if (item.type === 'TREE') {
                    const TREE =
                        <Col span={6}>
                            <FormItem name={field} label={label} key={field} {...formItemLayout}
                                      initialValue={initialValue}>
                                <TreeSelect style={{width: width}}>
                                    {this.renderTreeNodes(item.list)}
                                </TreeSelect>
                            </FormItem>
                        </Col>;
                    formItemList.push(TREE)
                } else if (item.type === 'AREA_TREE') {
                    const AREA_TREE =
                        <Col span={6}>
                            <FormItem name={field} label={label} key={field} {...formItemLayout}
                                      initialValue={initialValue}>
                                <TreeSelect style={{width: width}}>
                                    {this.renderAreaTreeNodes(item.list)}
                                </TreeSelect>
                            </FormItem>
                        </Col>;
                    formItemList.push(AREA_TREE)
                }
            })
        }
        return formItemList;
    };

    render() {
        let {equipParam, unitParam} = this.state;
        let {button} = this.props;

        return (
            <div>
                <Form ref={this.form}>
                    <Row gutter={16}>
                        {this.initFormList()}
                    </Row>
                    {button === false ? null :
                        <FormItem style={{margin: '0 60px'}}>
                            <Button type="primary" style={{margin: '0 20px'}}
                                    onClick={this.handleFilterSubmit}>查询</Button>
                            <Button onClick={this.reset}>重置</Button>
                        </FormItem>
                    }
                </Form>
                <BaseModal
                    width={1200}
                    title="选择区域"
                    visible={this.state.areaVisible}
                    onCancel={() => this.setState({areaVisible: false})}
                    footer={null}
                    content={<AreaManage propsType="select" handleClick={(res) => {
                        // console.log(res);

                        this.form.current.setFieldsValue({
                            areaId: res.areaName
                        });

                        this.setState({
                            data: {areaId: res.areaId},
                            equipParam: {
                                ...equipParam,
                                areaId: res.areaId,
                                areaName: res.areaName,
                                areaPort: res.areaPort
                            },
                            unitParam: {
                                ...unitParam,
                                areaId: res.areaId,
                                areaName: res.areaName,
                                areaPort: res.areaPort
                            },
                            areaVisible: false
                        }, () => {
                            if (button === false) {
                                this.handleFilterSubmit();
                            }
                        });
                    }}/>}/>
                <BaseModal
                    width={1200}
                    title="选择单元"
                    visible={this.state.unitVisible}
                    onCancel={() => this.setState({unitVisible: false})}
                    footer={null}
                    content={<UnitManage
                        propsType="select" unitParam={unitParam}
                        handleClick={(res) => {
                            // console.log(res);
                            this.setState({
                                data: {equiUnit: res.equiUnit},
                                unitVisible: false
                            });
                            this.form.current.setFieldsValue({
                                equiUnit: res.equiUnit
                            })
                        }}/>}/>
                <BaseModal
                    width={1200}
                    title="选择设备"
                    visible={this.state.equipVisible}
                    onCancel={() => {
                        this.setState({equipVisible: false})
                    }}
                    footer={null}
                    content={<EquipmentManage
                        propsType="select" equipParam={equipParam}
                        handleClick={(res) => {
                            // console.log(res);
                            this.setState({
                                data: {equiId: res.equiId, equiName: res.equiName},
                                equipVisible: false
                            });
                            this.form.current.setFieldsValue({
                                equiId: res.equiName
                            })
                        }}/>}/>
            </div>
        );
    }
}

export default FilterForm;
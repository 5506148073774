import React, {Component} from 'react';
import {Form, Select, Input} from 'antd';
import './styles.less';
import axios from "../../../axios";
import {AUTH_ROLE_INFO} from "../../../axios/url";

const FormItem = Form.Item;
const Option = Select.Option;

export default class RoleForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checkedList: []
        };
    }

    componentDidMount() {
        // console.log(this.props);
        if (this.props.type === "view" || this.props.type === "edit") {
            this.getRoleAction(this.props.roleItem.roleCode);
        }
    }

    getRoleAction = (id) => {
        axios.ajax({
            url: AUTH_ROLE_INFO,
            method: 'get',
            data: {
                params: {roleCode: id}
            }
        }).then((res) => {
            let defaultValue = [];
            (res.DATA || []).map((item) => {
                defaultValue.push(item.authName);
            });
            this.setState({
                checkedList: defaultValue
            });
        })
    };

    render() {
        const formItemLayout = {labelCol: {span: 5}, wrapperCol: {span: 16}};
        let roleItem = {...this.props.roleItem, auth: this.state.checkedList};

        // console.log(roleItem);
        let disabled = this.props.type === "view";

        return (
            <Form layout="horizontal" ref={this.props.form}
                  fields={
                      [
                          {"name": "roleCode", "value": roleItem.roleCode || ''},
                          {"name": "roleName", "value": roleItem.roleName || ''},
                          {"name": "status", "value": roleItem.status || 0},
                          {"name": "remark", "value": roleItem.remark || ''},
                          {"name": "auth", "value": roleItem.auth || []}
                      ]}>
                {this.props.type !== "create" ?
                    <FormItem label="角色编号" name="roleCode"{...formItemLayout}>
                        <Input type="text" placeholder="请输入角色ID" disabled={true}/>
                    </FormItem> : null}
                <FormItem label="角色名称" name="roleName" {...formItemLayout} rules={[{
                    required: true,
                    message: "角色名称不能为空"
                }, {
                    pattern: "^(?=.*\\S).+$",//全空格检测
                    message: "角色名称不能为空"
                }]}>
                    <Input type="text" placeholder="请输入角色名称" disabled={disabled}/>
                </FormItem>
                <FormItem label="状态" name="status"{...formItemLayout}>
                    <Select disabled={disabled}>
                        <Option key={1} value={1}>开启</Option>
                        <Option key={0} value={0}>关闭</Option>
                    </Select>
                </FormItem>
                <FormItem label="备注" name="remark"{...formItemLayout}>
                    <Input.TextArea type="text" placeholder="请输入备注" allowClear disabled={disabled}/>
                </FormItem>
                {this.props.type === "view" || this.props.type === "edit" ?
                    <FormItem label="权限" name="auth"{...formItemLayout}>
                        <Input.TextArea type="text" placeholder="" disabled autoSize={{minRows: 4, maxRows: 6}}/>
                    </FormItem> : null}
            </Form>
        );
    }
}
import React, {Component} from 'react';
import {Button, Checkbox, Col, Form, Input, message, Modal, Row, Select} from "antd";
import AreaManage from "../../manage/areaManage";
import BaseModal from "../../../component/BaseModal";
import axios from "../../../axios";
import {CORROSION_ADD, CORROSION_LIST, PORT_SEND_MESSAGE, USER_DELETE} from "../../../axios/url";
import Utils, {pageSize} from "../../../utils";
import BaseTool from "../../../component/BaseTool";
import BasePage from "../../../component/BasePage";
import Data from "../receiveData/data";
import form from '../../../utils/constant';
import './styles.less';

const FormItem = Form.Item;
const Option = Select.Option;
const confirm = Modal.confirm;
const CheckboxGroup = Checkbox.Group;

const formList = [
    {
        type: 'INPUTSELECT',
        label: "区域",
        field: 'areaId',
        visible: "area"
    }, {
        type: 'INPUT',
        label: "单元",
        field: 'equiUnit',
    }, {
        type: 'INPUTSELECT',
        label: "设备",
        field: 'equiId',
        visible: "equip"
    }, {
        type: 'RANGE',
        label: "起止时间",
        field: 'time',
    }
];

export default class CorrosionData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            dataVisible: false,
            areaVisible: false,
            data: Array(4).fill().map((_, i) => i + 1),

            corrosionData: {},//存储区域信息
            dataSource: [],

            isClear: false
        };
        this.form = React.createRef();

        this.params = {pageNo: 1};
        this.params1 = {};//筛选参数
    }

    componentDidMount() {
        this.getList();
    }

    getList = () => {
        // console.log(this.params1);
        if (this.params1.time) {
            this.params1.startTime = this.params1.time[0];
            this.params1.endTime = this.params1.time[1];
        }
        axios.PostAjax({
            url: CORROSION_LIST,
            data: {
                params: {...this.params1, current: this.params.pageNo, size: pageSize}
            }
        }).then(res => {
            // console.log(res);
            this.setState({
                isClear: true,
                dataSource: (res.DATA.corrosionDataList || []).map((item, index) => {
                    item.key = pageSize * (this.params.pageNo - 1) + index + 1;
                    return item;
                }),
                pagination: Utils.pagination(res.DATA, (current) => {
                    this.params.pageNo = current;//当前页数
                    this.getList(); //刷新列表数据
                })
            })
        });
    };

    //获取所有区域及对应端口号

    //获取区域设备数

    handleOperation = (type, record) => {
        const {dataSource, selectedRowKeys} = this.state;
        switch (type) {
            case "view":
                this.setState({
                    item: record,
                    dataVisible: true
                });
                break;
            case "add":
                this.setState({
                    visible: true
                });
                break;
            case "delete":
                this.handleDelete(type, record);
                break;
            case "deleteAll":
                this.handleDelete(type, record);
                break;
            case "compare":
                // let selectedData = [];
                // dataSource.map(item => {
                //     selectedRowKeys.map(item1 => {
                //         if (item.id == item1) selectedData.push({...item})
                //     })
                // });
                // // console.log(selectedData);
                //
                // this.setState({
                //     dataVisible: true,
                //     selectedData
                // });
                break;
        }

        this.setState({type});
    };

    resetList = (res) => {
        if (res) this.params1 = res;
        this.params.pageNo = 1;
        this.getList();
    };

    handleDelete = (type, item) => {
        let count = (this.state.selectedIds || []).length;
        // console.log(this.state.selectedIds);
        if (type === "delete" || (type === "deleteAll" && count > 0)) {
            confirm({
                title: type === "delete" ? '确认删除?' : '确认删除' + count + '项?',
                okText: '是',
                okType: 'danger',
                cancelText: '否',
                onOk: () => {
                    axios.ajax({
                        url: USER_DELETE,
                        method: "delete",
                        data: {
                            params: type === "delete" ? {idList: item.id} : {idList: this.state.selectedIds.toString()}
                        }
                    }).then((res) => {
                        message.success(res.DATA);
                        this.resetList();
                    })
                }
            });
        } else if (count === 0) {
            message.info("请选择数据");
        }
    };

    //发送数据
    handleOk = () => {
        let data1 = this.form.current.getFieldsValue();
        const {data, corrosionData} = this.state;
        // console.log(data, data1);//data：设备列表
        let message1 = form.START_FORM + form.CORROSION_FORM;
        Object.keys(data1).forEach(function (key) {
            // console.log(key, data1[key]);
            if (key !== "areaName") {
                // console.log(data1[key].replace(/./g, ""));
                message1 += data1[key].replace(/./g, "");//去掉.
            }
        });
        message1 += form.END_FORM;

        axios.ajax({
            url: PORT_SEND_MESSAGE,
            method: 'get',
            data: {
                params: {message: message1}
            }
        }).then((res) => {
            message.success(res.DATA);
            this.setState({visible: false, equipParam: {}});
            this.form.current.resetFields();//清除表单
            this.resetList();
        });
    };

    onCancel = () => {
        if (this.form.current) this.form.current.resetFields();
        this.setState({visible: false, dataVisible: false, item: {}})
    };

    reset = () => {
        if (this.form.current) this.form.current.resetFields();
    };

    render() {
        const formItemLayout = {labelCol: {span: 6}, wrapperCol: {span: 16}};

        const {data, corrosionData, item, visible, dataVisible, selectedData, type, isClear} = this.state;

        let head = <div style={{
            position: "relative",
            float: "right",
            marginBottom: "20px",
            display: this.props.userType === 2 ? "none" : "block"//只对管理员显示
        }}>
            {/*<BaseTool type="headButton" name="删除数据" onClick={() => this.handleOperation("deleteAll")}/>*/}
            <BaseTool type="headButton" name="数据对比" onClick={() => this.handleOperation("compare")}/>
            {/*<BaseTool type="headButton" name="发送数据" onClick={() => this.handleOperation("add")}/>*/}
        </div>;

        const columns = [
            {
                title: '序号',
                dataIndex: 'key',
                width: 80
            }, {
                title: '区域',
                dataIndex: 'areaName'
            }, {
                title: '区域位置',
                dataIndex: 'areaLocation'
            }, {
                title: '单元',
                dataIndex: 'equiUnit'
            }, {
                title: '设备编号',
                dataIndex: 'equiId',
            }, {
                title: '设备名称',
                dataIndex: 'equiName',
            }, {
                title: '数据',
                dataIndex: 'corrosionData',
            }, {
                title: '发送时间',
                dataIndex: 'operationTime',
                render: text => {
                    return Utils.formatDate(text);
                }
            }];

        return (
            <div className="corrosion">
                <BasePage formList={formList} head={head} columns={columns}
                          dataSource={this.state.dataSource} isClear={isClear}
                          updateClearFlag={() => this.setState({isClear: false})}
                          getList={(res) => this.resetList(res)}
                          pagination={this.state.pagination}
                          updateSelectedItem={(selectedRowKeys, record, selectedIds) => {
                              // console.log(selectedRowKeys, record, selectedIds);
                              this.setState({selectedRowKeys, record, selectedIds})
                          }}/>
                <BaseModal
                    width={600}
                    title={"发送腐蚀数据"}
                    visible={visible}
                    onCancel={this.onCancel}
                    footer={null}
                    content={
                        <Form ref={this.form} onFinish={() => this.handleOk()} scrollToFirstError
                              fields={
                                  [
                                      {"name": "areaName", "value": corrosionData.areaName || ""},
                                  ]
                              }>
                            <FormItem label="区域" {...formItemLayout} name='areaName' rules={[{required: true}]}>
                                <Input placeholder=""
                                       onClick={() => this.setState({areaVisible: true})}/>
                            </FormItem>
                            {data.map((item, index) =>
                                <FormItem key={index} label={item.equiName || '编号' + (index + 1)} {...formItemLayout}
                                          name={item.id}
                                          rules={[{required: true}, {
                                              pattern: /^\d{4}$|^\d{2}\.\d{2}$/g,
                                              message: '数据格式错误！'
                                          }]}>
                                    <Input/>
                                </FormItem>
                            )}
                            <Form.Item>
                                <Row>
                                    <Col span={12} style={{textAlign: 'right'}}>
                                        <Button type="primary" onClick={this.reset}>
                                            重置
                                        </Button>
                                    </Col>
                                    <Col span={12} style={{textAlign: 'left', paddingLeft: 30}}>
                                        <Button type="primary" htmlType="submit">
                                            提交
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                    }/>
                <BaseModal
                    width={1200}
                    title="选择区域"
                    visible={this.state.areaVisible}
                    onCancel={() => this.setState({areaVisible: false})}
                    footer={null}
                    content={<AreaManage propsType="select" handleClick={(res) => {
                        // console.log(res);
                        this.setState({
                            corrosionData: {...corrosionData, areaId: res.id, areaName: res.areaName},
                            areaVisible: false,
                            data: res.equipmentList || []
                        })
                    }}/>}/>
                <BaseModal
                    width={800}
                    title={"查看数据"}
                    visible={dataVisible}
                    onCancel={this.onCancel}
                    footer={null}
                    content={
                        <Data item={item} type={type} selectedData={selectedData}/>
                    }
                />
            </div>
        );
    }
}
import React, {Component} from 'react';
import {Checkbox, Tree, Menu} from "antd";
import axios from "../../../axios";
import './styles.less'
import {AUTH_LIST, AUTH_ROLE_INFO} from "../../../axios/url";

const CheckboxGroup = Checkbox.Group;
const TreeNode = Tree.TreeNode;

export default class RoleAction extends Component {

    state = {
        checkedList: [],
        options: [],
        actionList: []
    };

    componentDidMount() {
        this.getActionList();
        // console.log(this.props.roleItem);
        if (this.props.type === 'config') this.getRoleAction(this.props.roleItem.roleCode);
    }

    onChange = (checkedKeys, {checked, checkedNodes, node, event, halfCheckedKeys}) => {
        // console.log(checkedKeys);
        // console.log(this.state.actionList);
        // console.log(checked, checkedNodes, node, event, halfCheckedKeys);
        this.setState({
            checkedList: checkedKeys
        });

        let actionList = [];
        this.state.actionList.map((item) => {
            // console.log(item.id);
            // console.log((checkedKeys.checked || []).length);
            for (let i = 0; i < (checkedKeys.checked || []).length; i++) {
                // console.log(checkedKeys[i]);
                if (item.id.toString() === checkedKeys.checked[i]) {
                    actionList.push(item);
                }
            }
        });
        this.props.dispatchRoleAction(actionList);
    };

    //全部权限
    getActionList = () => {
        axios.PostAjax({
            url: AUTH_LIST,
            data: {
                params: {}
            }
        }).then((res) => {
            let plainOptions = [];
            // (res.DATA || []).map((item) => {
            //     plainOptions.push(item.id);
            // });
            this.setState({
                options: this.getParentAction(res.DATA || []),
                actionList: res.DATA
            })
        })
    };

    //该角色下权限
    getRoleAction = (id) => {
        axios.ajax({
            url: AUTH_ROLE_INFO,
            method: 'get',
            data: {
                params: {roleCode: id}
            }
        }).then((res) => {
            let defaultValue = [];
            (res.DATA || []).map((item) => {
                defaultValue.push(item.id.toString());//需要转下类型
            });
            this.setState({
                checkedList: defaultValue
            });
        })
    };

    //权限转为树形
    getParentAction = (data) => {
        let array = {};
        let actionList = [];
        data.forEach(i => {
            i['children'] = [];
            array[i.id] = i;
        });
        data.forEach(i => {
            if (i.parentCode === "" || i.parentCode === null || i.parentCode === -1) {
                i.parentCode = 0;
                i.level = 1;
            }
            // console.log(i);
            // console.log(array[i.parentCode]);
            if (i.parentCode !== 0) {
                // console.log(i.parentCode);
                if (array[i.parentCode]) array[i.parentCode].children.push(i);
            } else {
                actionList.push(i);
            }
        });

        return actionList;
    };

    //解析权限树
    renderTreeNodes = (data) => {
        // console.log("renderTreeNodes");
        return data.map((item) => {
            if (item.children && (item.children || []).length !== 0) {
                return (
                    <TreeNode title={item.authName} key={item.id}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            } else {
                return (
                    <TreeNode title={item.authName} key={item.id}/>
                );
            }
        });
    };

    render() {
        // console.log(this.state);

        return (
            <div>
                {/*<CheckboxGroup*/}
                {/*className="role-action"*/}
                {/*options={this.state.options}*/}
                {/*value={this.state.checkedList}*/}
                {/*onChange={this.onChange}/>*/}
                <Tree
                    checkable//节点前添加 Checkbox 复选框
                    checkStrictly//checkable 状态下节点选择完全受控（父子节点选中状态不再关联）
                    checkedKeys={this.state.checkedList}
                    onCheck={this.onChange}
                    onSelect={this.onChange}
                >
                    {this.renderTreeNodes(this.state.options || [])}
                </Tree>
            </div>
        );
    }
}
import {message} from "antd";
import Constant from "./constant";

/**
 * 地图
 * @param data
 * @returns {*}
 */
export function getTreeData(data) {
    for (let i = 0; i < data.length; i++) {
        // console.log(data[i]);
        if (data[i].districts.length < 1) {
            // districts若为空数组，则将districts设为undefined
            data[i].districts = undefined;
        } else {
            // districts若不为空数组，则继续 递归调用 本方法
            getTreeData(data[i].districts);
        }
    }
    return data;
}

/**
 * 状态显示
 * @param e
 * @returns {string}
 */
export function setStatus(e) {
    return e === 1 ? "启用" : "停用";
}

/**
 * 校验密码
 * @param data
 * @returns {boolean}
 */
export function checkPassword(data) {
    let flag = false;
    if (data.oldPassword === "") {
        message.error("原密码不能为空");
    } else if (data.oldPassword !== localStorage.getItem(Constant.NAMESPANCE_PASS) ? localStorage.getItem(Constant.NAMESPANCE_PASS) : "") {
        message.error("原密码输入错误");
    } else if (data.newPassword === data.oldPassword) {
        message.error("新旧密码不能相同");
    } else if (data.newPassword === "") {
        message.error("新密码不能为空");
    } else if (data.newPassword !== data.newPassword1) {
        message.error("两次新密码不同");
    } else {
        flag = true;
    }

    return flag;
}

/**
 *
 * @param item
 * @param type 1：类型，2：数值，3：单位
 */
export function setReceive(item, type) {
    let values = ["corrosionData", "temperature", "clConcentration", "ph", ''];
    let units = ["mm/a", "℃", "%", "-", ''];
    switch (type) {
        case 1:
            return item === 0 ? "腐蚀速率" : item === 1 ? "温度" : item === 2 ? "氯离子浓度" : "PH值";
        case 2:
            return values[(item || {}).receiveType || 0];
        case 3:
            return units[(item || {}).receiveType || 0];
    }
}

export function warning(item) {
    // console.log(item);
    // if (!!item)
    //     return "green";

    if (item.status === 0) {//设备停用
        return "gray";
    }

    //默认腐蚀数据
    let value = item ? item[`${setReceive(item, 2)}`] : 0;
    let type = 1;

    // console.log(item, type, value);

    switch (type) {
        case 1://腐蚀数据
            return value < 1 ? "#08d508" : value >= 1 && value < 10 ? "#ffda22" : value >= 10 && value < 100 ? "red" : "red1";
        case 2://pH值
            return value > 12 ? "#08d508" : value >= 10 && value <= 12 ? "#ffda22" : "red";
        case 3://氯离子浓度
            return value < 0.1 ? "#08d508" : value >= 0.1 && value <= 3 ? "#ffda22" : "red";
        default:
            return "gray";
    }
}

/**
 * 鼠标事件
 * @param div 小div
 * @param type 管理/查看
 * @param item 数据
 * @param getMarker 点击事件
 * @param area 上级div
 * @param x 位置x
 * @param y 位置y
 */
export function mouseEvent(div, type, area, x, y, item, getMarker) {
    let type1 = type === "manage";
    div.onclick = () => {
        if (!type1) getMarker(item);
    };

    div.onmouseenter = () => {
        // console.log(item);
        let div = document.createElement("div");
        div.className = "text";
        div.style.position = "absolute";
        div.style.left = x + 20 + 'px';
        div.style.top = y + 'px';
        div.style.zIndex = 10;
        div.style.padding = '0 5px';
        div.style.fontWeight = 'bolder';
        div.setAttribute("align", "center");
        div.setAttribute("id", "div" + item.key);
        div.innerText = type1 ? item.equiName : item.equiName + ': ' + item.corrosionData;

        document.getElementById(area).appendChild(div);
    };

    div.onmouseleave = () => {
        // console.log(area);
        // console.log(document.getElementById(area));

        document.getElementById(area).removeChild(document.getElementById("div" + item.key));
    };
}

/**
 * 设置头像名称
 * @param icon
 * @param realName
 * @returns {*}
 */
export function setIconName(icon, realName) {
    if ((icon === null || icon === "") && !!realName) {
        if (realName.length <= 2) {
            return realName;
        } else {
            return realName.substring(realName.length - 2, realName.length);
        }
    } else {
        return "";
    }
}
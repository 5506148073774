import React, {Component} from 'react';
import * as echarts from "echarts";

export default class Data extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.setData();
    }

    setData = () => {
        let myChart = echarts.init(document.getElementById('main'));
        if (this.props.type !== "compare") {
            let item = this.props.item;
            // console.log(myChart);
            console.log(item);
            let data = (item.receiveData || item.corrosionData || "").split(";").slice(0, -1);
            for (let i = 0; i < data.length; i++) {
                data[i]=data[i]/10;
            }
            let length = data.length || 0;

            myChart.setOption({
                // title: {
                //     text: '数据图'
                // },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    name: '时间/s',
                    nameTextStyle: {
                        fontWeight: 'bold',
                        fontSize: 16
                    },
                    data: Array(length).fill().map((_, i) => i + 1)
                },
                yAxis: {
                    type: 'value',
                    name: '电势/mV',
                    nameTextStyle: {
                        fontWeight: 'bold',
                        fontSize: 16
                    },
                    boundaryGap: [0, '30%']
                },
                grid: {
                    show: true,
                    containLabel: true
                },
                // label: {
                //     show: true,
                //     position: 'top'
                // },
                series: [
                    {
                        data,
                        type: 'line',
                        // smooth: true
                    }
                ]

                // series: [{
                //     type: 'radar',
                //     data: [
                //         {
                //             value: abilityTrees.map(item => {
                //                 return item.score;
                //             }),
                //         }
                //     ]
                // }]
            });
        }

    };

    render() {
        return (
            <div>
                <div id='main' style={{width: 700, height: 400}}/>
            </div>
        );
    }
}
import React, {Component} from "react";
import BasePage from "../../../component/BasePage";
import {Avatar, Button, Col, Form, Input, message, Modal, Row, Select, Checkbox} from "antd";
import BaseTool from "../../../component/BaseTool";
import BaseModal from "../../../component/BaseModal";
import axios, {commonUrl, picUrl1, url, url1} from "../../../axios";
import './styles.less';
import {
    DOWNLOAD_USER, ROLE_LIST, ROLE_USER_INFO, ROLE_USER_UPDATE, UPLOAD_PIC,
    USER_DELETE, USER_EXCEL_IMPORT, USER_INSERT, USER_LIST, USER_UPDATE
} from "../../../axios/url";
import {EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';
import Utils, {pageSize} from "../../../utils";
import UserArea from "./userArea";
import {setIconName} from "../../../utils/dataDeal";

const FormItem = Form.Item;
const Option = Select.Option;
const confirm = Modal.confirm;
const CheckboxGroup = Checkbox.Group;

const formList = [
    {
        type: 'INPUT',
        label: "姓名",
        field: 'userName',
    }, {
        type: 'INPUT',
        label: "账号",
        field: 'userCode',
    }, {
        type: 'SELECT',
        label: "类型",
        field: 'userType',
        list: [
            {id: "-1", name: "管理员"},
            {id: "1", name: "普通用户"},
        ]
    }, {
        type: 'SELECT',
        label: "状态",
        field: 'status',
        list: [
            {id: 1, name: "开启"},
            {id: 0, name: "关闭"},
        ]
    }
];

const formItemLayout = {labelCol: {span: 6}, wrapperCol: {span: 16}};

export default class UserManage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            userItem: {},

            checkedList: [],
            oldCheckedList: [],
            options: [],
            roleList: [],

            isClear: false,

            icon: ''
        };
        this.form = React.createRef();
        this.formPsd = React.createRef();

        this.params = {pageNo: 1};
        this.params1 = {};//筛选参数
    }

    componentDidMount() {
        this.getList();
        this.getRoleList();
    }

    getList = () => {
        axios.PostAjax({
            url: USER_LIST,
            data: {
                params: {...this.params1, current: this.params.pageNo, size: pageSize}
            }
        }).then(res => {
            // console.log(res);
            this.setState({
                isClear: true,
                dataSource: (res.DATA.sysUserList || []).map((item, index) => {
                    item.key = pageSize * (this.params.pageNo - 1) + index + 1;
                    return item;
                }),
                pagination: Utils.pagination(res.DATA, (current) => {
                    this.params.pageNo = current;//当前页数
                    this.getList(); //刷新列表数据
                })
            })
        });
    };

    getUserRole = (userCode) => {
        axios.ajax({
            url: ROLE_USER_INFO,
            method: "get",
            data: {
                params: {userCode: userCode}
            }
        }).then((res) => {
            let defaultValue = [];
            (res.DATA || []).map((item) => {
                defaultValue.push(item.roleName);
            });
            this.setState({
                checkedList: defaultValue,
                oldCheckedList: defaultValue,
            });
        })
    };

    getRoleList = () => {
        axios.PostAjax({
            url: ROLE_LIST,
            data: {
                params: {}
            }
        }).then((res) => {
            let plainOptions = [];
            (res.DATA || []).map((item) => {
                plainOptions.push(item.roleName);
            });
            this.setState({
                options: plainOptions,
                roleList: res.DATA
            })
        })
    };

    handleOperation = (type, record) => {
        switch (type) {
            case "view":
                this.setState({
                    userItem: record,
                    icon: record.icon || '',
                    visible: true
                });
                break;
            case "change":
                this.setState({
                    userItem: record,
                    icon: record.icon,
                    visible: true
                });
                break;
            case "changePsd":
                this.setState({
                    userItem: record,
                    visiblePsd: true
                });
                break;
            case "delete":
                this.handleDelete(type, record);
                break;
            case "add":
                this.setState({
                    visible: true
                });
                break;
            case "template":
                window.open(url + DOWNLOAD_USER, "_parent");
                break;
            case "config":
                this.setState({
                    userItem: record,
                    visibleRole: true
                });
                this.getUserRole(record.userCode);
                break;
            case "configArea":
                this.setState({
                    userItem: record,
                    visibleArea: true
                });
                break;
            case "deleteAll":
                this.handleDelete(type, record);
                break;
        }

        this.setState({type});
    };

    resetList = (res) => {
        if (res) this.params1 = res;
        this.params.pageNo = 1;
        this.getList();
    };

    handleDelete = (type, item) => {
        // console.log(this.state.selectedItem, this.state.record, this.state.selectedIds);
        let count = (this.state.selectedIds || []).length;
        // console.log(this.state.selectedIds);
        // console.log(this.state.selectedIds.toString());
        if (type === "delete" || (type === "deleteAll" && count > 0)) {
            confirm({
                title: type === "delete" ? '确认删除?' : '确认删除' + count + '项?',
                okText: '是',
                okType: 'danger',
                cancelText: '否',
                onOk: () => {
                    axios.ajax({
                        url: USER_DELETE,
                        method: "delete",
                        data: {
                            params: type === "delete" ? {idList: item.id} : {idList: this.state.selectedIds.toString()}
                        }
                    }).then((res) => {
                        message.success(res.DATA);
                        this.resetList();
                    })
                }
            });
        } else if (count === 0) {
            message.info("请选择用户");
        }
    };

    handleSubmit = () => {
        let data = this.form.current.getFieldsValue();
        let {icon} = this.state;
        // console.log(data, icon);

        axios.PostAjax({
            url: this.state.type === "add" ? USER_INSERT : USER_UPDATE,
            data: {
                params: this.state.type === "add" ? {...data, icon} : {...data, id: this.state.userItem.id, icon}
            }
        }).then((res) => {
            message.success(res.DATA);
            this.setState({visible: false});
            this.form.current.resetFields();//清除表单
            this.resetList();
        });
    };

    changePsd = () => {
        let data = this.formPsd.current.getFieldsValue();
        // console.log(data);
        // console.log(this.state);

        this.formPsd.current.resetFields();//清除表单
        axios.PostAjax({
            url: USER_UPDATE,
            data: {
                params: {
                    ...data,
                    userCode: this.state.userItem.userCode,
                    id: this.state.userItem.id
                }
            }
        }).then((res) => {
            message.success(res.DATA);
            this.setState({visiblePsd: false});
            this.getList();
        });
    };

    onChange = () => {

    };

    onChangeRole = (checkedList) => {
        this.setState({
            checkedList
        });

        let roleList = [];
        this.state.roleList.map((item) => {
            for (let i = 0; i < checkedList.length; i++) {
                if (item.roleName == checkedList[i]) {
                    roleList.push(item);
                }
            }
        });

        this.setState({
            roleCheckedList: roleList
        })
    };

    handleUserRole = () => {
        let roleIds = [];
        (this.state.roleCheckedList || []).map((item) => {
            roleIds.push(item.id);
        });

        let data = [];
        (this.state.roleCheckedList || []).map((item) => {
            data.push({
                userCode: this.state.userItem.userCode,
                roleCode: item.roleCode
            });
        });

        if (data.length === 0) {
            if (this.state.checkedList.length === 0) {
                message.error("请选择角色");
            } else {
                message.error("请修改角色");
            }
        } else {
            axios.PostAjax({
                url: ROLE_USER_UPDATE,
                data: {
                    params: data
                }
            }).then((res) => {
                message.success(res.DATA);
                this.setState({
                    visibleRole: false,
                    roleCheckedList: []//清空
                })
            })
        }
    };

    onCancel = () => {
        if (this.form.current) this.form.current.resetFields();
        if (this.formPsd.current) this.formPsd.current.resetFields();
        this.setState({visible: false, visiblePsd: false, visibleRole: false, visibleArea: false, userItem: {}})
    };

    render() {
        const {icon, type, userItem, isClear, realName} = this.state;
        let disabled = type === "view";
        let disabled1 = type === "add";

        let head = <div style={{
            position: "relative",
            float: "right",
            marginBottom: "20px",
            display: this.props.userType === 2 ? "none" : "block"//只对管理员显示
        }}>
            <BaseTool type="headButton" name="删除用户" display onClick={() => this.handleOperation("deleteAll")}/>
            <BaseTool type="importButton" name="导入用户" display fileType="excel"
                      style={{float: "right", marginRight: "20px"}}
                      title='file' action={commonUrl + USER_EXCEL_IMPORT}
                      handleValue={() => this.resetList()}/>
            <BaseTool type="headButton" name="下载模板" display onClick={() => this.handleOperation("template")}/>
            <BaseTool type="headButton" name="添加用户" display onClick={() => this.handleOperation("add")}/>
        </div>;

        const columns = [
            {
                title: '序号',
                dataIndex: 'key'
            }, {
                title: '账号',
                dataIndex: 'userCode'
            }, {
                title: '姓名',
                dataIndex: 'userName',
            }, {
                title: '电话',
                dataIndex: 'phone',
            }, {
                title: '邮箱',
                dataIndex: 'email',
            }, {
                title: '用户类型',
                dataIndex: 'userType',
                render: (text, item) => {
                    return text === -1 ? "管理员" : "普通用户"
                }
            }, {
                title: '状态',
                dataIndex: 'status',
                render(status) {
                    if (status === 1) {
                        return "启用"
                    } else {
                        return "停用"
                    }
                }
            }, {
                title: '操作',
                dataIndex: 'operation',
                render: (text, record) => {
                    return (
                        <div>
                            <BaseTool type="button" name="查看信息" onClick={() => this.handleOperation("view", record)}/>
                            <BaseTool type="button" name="修改信息" onClick={() => this.handleOperation("change", record)}/>
                            <BaseTool type="button" name="分配角色" onClick={() => this.handleOperation("config", record)}/>
                            <BaseTool type="button" name="分配区域"
                                      onClick={() => this.handleOperation("configArea", record)}/>
                            <BaseTool type="button" name="修改密码"
                                      onClick={() => this.handleOperation("changePsd", record)}/>
                            <BaseTool type="button" name="删除" display
                                      onClick={() => this.handleOperation("delete", record)}/>
                        </div>
                    )
                }
            }];

        return (
            <div className="user">
                <BasePage formList={formList} head={head} columns={columns}
                          dataSource={this.state.dataSource} isClear={isClear}
                          getList={(res) => this.resetList(res)}
                          pagination={this.state.pagination}
                          updateClearFlag={() => this.setState({isClear: false})}
                          updateSelectedItem={(selectedRowKeys, record, selectedIds) => {
                              // console.log(selectedRowKeys, record, selectedIds);
                              this.setState({selectedRowKeys, record, selectedIds})
                          }}/>
                <BaseModal
                    width={600}
                    title={type === "add" ? "新增用户" : type === "change" ? "修改用户" : "查看用户"}
                    visible={this.state.visible}
                    // onOk={() => this.handleSubmit()}
                    onCancel={this.onCancel}
                    footer={null}
                    content={
                        <div>
                            <Row>
                                <Col span={5} style={{textAlign: "center"}}>
                                    <Avatar src={picUrl1 + icon} size={90}
                                            style={{color: '#f56a00', backgroundColor: '#fde3cf'}}>
                                        {setIconName(icon, realName)}
                                    </Avatar>
                                    {!disabled ?
                                        <BaseTool type="importButton" fileType='image' name="上传头像"
                                                  title='multipartFile' action={url1 + UPLOAD_PIC}
                                                  style={{marginTop: 10}} size="middle"
                                                  handleValue={(file) => this.setState({icon: file.response.DATA})}/>
                                        : null}
                                </Col>
                                <Col span={18}>
                                    <Form ref={this.form} onFinish={this.handleSubmit} scrollToFirstError
                                          fields={
                                              [
                                                  {"name": "userName", "value": userItem.userName || ''},
                                                  {"name": "userCode", "value": userItem.userCode || ''},
                                                  {"name": "userType", "value": userItem.userType || 1},
                                                  {"name": "phone", "value": userItem.phone || ''},
                                                  {"name": "email", "value": userItem.email || ''},
                                                  {"name": "status", "value": userItem.status || 1},
                                                  {"name": "remark", "value": userItem.remark || ''}
                                              ]
                                          }>
                                        <FormItem label="姓名" {...formItemLayout} name='userName'
                                                  rules={[{required: true, message: '请输入姓名！'}]}>
                                            <Input placeholder="" disabled={disabled}/>
                                        </FormItem>
                                        <FormItem label="账号" {...formItemLayout} name='userCode'
                                                  rules={[{required: true, message: '请输入账号！'}]}>
                                            <Input placeholder="" disabled={!disabled1}/>
                                        </FormItem>
                                        <FormItem label="用户类型"  {...formItemLayout} name='userType'
                                                  rules={[{required: true, message: '请选择用户类型！'}]}>
                                            <Select onChange={this.onChange} disabled={disabled}>
                                                <Option value={-1}>管理员</Option>
                                                <Option value={1}>普通用户</Option>
                                            </Select>
                                        </FormItem>
                                        <FormItem label="手机号码"  {...formItemLayout} name='phone'
                                                  hasFeedback validateTrigger={['onBlur']}
                                                  rules={[{required: true, message: '请输入手机号码！'}, {
                                                      pattern: /^(1[0-9])\d{9}$/,
                                                      message: '手机号格式错误！'
                                                  }]}>
                                            <Input disabled={disabled}/>
                                        </FormItem>
                                        <FormItem label="邮箱"  {...formItemLayout} name='email'
                                                  hasFeedback validateTrigger={['onBlur']}
                                                  rules={[
                                                      {required: true, message: '请输入邮箱！'},
                                                      {
                                                          pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
                                                          message: '邮箱格式错误！'
                                                      }]}>
                                            <Input disabled={disabled}/>
                                        </FormItem>
                                        <FormItem label="状态" name="status"{...formItemLayout}>
                                            <Select disabled={disabled}>
                                                <Option key={1} value={1}>开启</Option>
                                                <Option key={0} value={0}>关闭</Option>
                                            </Select>
                                        </FormItem>
                                        <FormItem label="备注" name='remark'{...formItemLayout}>
                                            <Input.TextArea type="text" autosize={{minRows: 4, maxRows: 6}}
                                                            placeholder="请输入备注" disabled={disabled}/>
                                        </FormItem>
                                        <Form.Item>
                                            {type === "view" ?
                                                <Row>
                                                    <Col span={24} style={{textAlign: 'center'}}>
                                                        <Button type="primary" onClick={this.onCancel}>
                                                            确定
                                                        </Button>
                                                    </Col>
                                                </Row> :
                                                <Row>
                                                    <Col span={12} style={{textAlign: 'right'}}>
                                                        <Button type="primary" onClick={this.onCancel}>
                                                            取消
                                                        </Button>
                                                    </Col>
                                                    <Col span={12} style={{textAlign: 'left', paddingLeft: 30}}>
                                                        <Button type="primary" htmlType="submit">
                                                            提交
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            }
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    }
                />
                <BaseModal
                    width={600}
                    title={"修改密码"}
                    visible={this.state.visiblePsd}
                    onCancel={this.onCancel}
                    footer={null}
                    content={
                        <div>
                            <Form ref={this.formPsd} onFinish={this.changePsd} scrollToFirstError>
                                <p style={{
                                    color: "red",
                                    margin: "-18px 0 10px 135px",
                                    fontSize: "small"
                                }}>密码至少8位，包括数字、大小写字母和特殊字符三种及以上</p>
                                <FormItem label="新密码"  {...formItemLayout} name='password'
                                          hasFeedback validateTrigger={['onBlur']}
                                          rules={[{required: true, message: '请输入新密码！'}, {
                                              pattern: /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{8,20}$/,
                                              message: '密码格式错误！'
                                          }]}>
                                    <Input.Password placeholder="输入新密码" allowClear
                                                    iconRender={visible => (visible ? <EyeTwoTone/> :
                                                        <EyeInvisibleOutlined/>)}/>
                                </FormItem>
                                <Form.Item>
                                    <Row>
                                        <Col span={12} style={{textAlign: 'right'}}>
                                            <Button type="primary" onClick={this.onCancel}>
                                                取消
                                            </Button>
                                        </Col>
                                        <Col span={12} style={{textAlign: 'left', paddingLeft: 15}}>
                                            <Button type="primary" htmlType="submit">
                                                提交
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Form>
                        </div>
                    }
                />
                <BaseModal
                    width={600}
                    title={"分配角色"}
                    visible={this.state.visibleRole}
                    onOk={this.handleUserRole}
                    onCancel={this.onCancel}
                    content={
                        <CheckboxGroup
                            className="user-role"
                            options={this.state.options}
                            value={this.state.checkedList}
                            onChange={this.onChangeRole}/>
                    }
                />
                <BaseModal
                    width={1200}
                    title="分配区域"
                    visible={this.state.visibleArea}
                    onCancel={() => this.setState({visibleArea: false})}
                    footer={null}
                    content={<UserArea userItem={userItem}/>}/>
            </div>
        );
    }
}
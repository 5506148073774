import React, {Component} from 'react';
import {Button, Checkbox, Col, Form, Input, message, Modal, Radio, Row, Select} from "antd";
import BaseModal from "../../../component/BaseModal";
import AreaManage from "../../manage/areaManage";
import EquipmentManage from "../../manage/equipmentManage";
import axios from "../../../axios";
import {EQUIPMENT_PARAM_ADD, EQUIPMENT_PARAM_LIST, PORT_SEND_MESSAGE, PORT_START, PORT_STATE} from "../../../axios/url";
import Utils, {pageSize} from "../../../utils";
import BasePage from "../../../component/BasePage";
import BaseTool from "../../../component/BaseTool";
import './styles.less';
import form from "../../../utils/constant";
import UnitManage from "../../manage/unitManage";

const FormItem = Form.Item;
const Option = Select.Option;
const confirm = Modal.confirm;
const CheckboxGroup = Checkbox.Group;

const formList = [
    {
        type: 'INPUTSELECT',
        label: "区域",
        field: 'areaId',
        visible: "area"
    }, {
        type: 'INPUT',
        label: "单元",
        field: 'equiUnit',
    }, {
        type: 'INPUTSELECT',
        label: "设备",
        field: 'equiId',
        visible: "equip"
    }, {
        type: 'RANGE',
        label: "起止时间",
        field: 'time',
    }
];

export default class EquipmentData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status: false,//全部/部分修改
            data: Array(8).fill().map((_, i) => i + 1),

            dataSource: [],

            equipParam: {},
            value: 2,//状态

            isClear: false
        };

        this.form = React.createRef();

        this.params = {pageNo: 1};
        this.params1 = {};//筛选参数
    }

    componentDidMount() {
        this.getList();
    }

    getList = () => {
        if (this.params1.time) {
            this.params1.startTime = this.params1.time[0];
            this.params1.endTime = this.params1.time[1];
        }
        axios.PostAjax({
            url: EQUIPMENT_PARAM_LIST,
            data: {
                params: {...this.params1, current: this.params.pageNo, size: pageSize}
            }
        }).then(res => {
            // console.log(res);
            this.setState({
                isClear: true,
                dataSource: (res.DATA.equipmentParamList || []).map((item, index) => {
                    item.key = pageSize * (this.params.pageNo - 1) + index + 1;
                    return item;
                }),
                pagination: Utils.pagination(res.DATA, (current) => {
                    this.params.pageNo = current;//当前页数
                    this.getList(); //刷新列表数据
                })
            })
        });
    };

    //获取所有区域及对应端口号

    //获取区域设备数

    handleOperation = (type, record) => {
        switch (type) {
            case "view":
                this.setState({
                    equipParam: record,
                    visible: true
                });
                break;
            case "create":
                this.setState({
                    visible: true
                });
                break;
            case "delete":
                this.handleDelete(type, record);
                break;
            case "deleteAll":
                this.handleDelete(type, record);
                break;
        }

        this.setState({type});
    };

    resetList = (res) => {
        if (res) this.params1 = res;
        this.params.pageNo = 1;
        this.getList();
    };

    handleDelete = (type, item) => {
        let count = (this.state.selectedIds || []).length;
        // console.log(this.state.selectedIds);
        // if (type === "delete" || (type === "deleteAll" && count > 0)) {
        //     confirm({
        //         title: type === "delete" ? '确认删除?' : '确认删除' + count + '项?',
        //         okText: '是',
        //         okType: 'danger',
        //         cancelText: '否',
        //         onOk: () => {
        //             axios.ajax({
        //                 url: USER_DELETE,
        //                 method: "delete",
        //                 data: {
        //                     params: type === "delete" ? {idList: item.id} : {idList: this.state.selectedIds.toString()}
        //                 }
        //             }).then((res) => {
        //                 message.success(res.DATA);
        //                 this.resetList();
        //             })
        //         }
        //     });
        // } else if (count === 0) {
        //     message.info("请选择数据");
        // }
    };

    onChange = e => {
        // console.log('radio checked', e.target.value);
        this.setState({
            value: e.target.value,
            status: e.target.value !== 2
        });
    };

    portState = () => {
        let {equipParam} = this.state;
        let port = equipParam.areaPort;

        // axios.ajax({
        //     url: PORT_STATE,
        //     method: "get",
        //     data: {
        //         params: {port}
        //     }
        // }).then((res) => {
        //     // console.log(res);
        //     let data = res.DATA;
        //     let state = Utils.portStatus(data);
            // if (state === "连接成功") {
                this.handleOk();
            // } else if (state === "启动未连接") {
            //     message.info("启动未连接");
            // } else {
            //     axios.ajax({
            //         url: PORT_START,
            //         method: "get",
            //         data: {
            //             params: {port}
            //         }
            //     }).then((res) => {
            //         // console.log(res);
            //         if (res.DATA === "启动成功") {
            //             this.handleOk();
            //         } else {
            //             Modal.error({
            //                 title: "提示",
            //                 content: res.MESSAGE
            //             });
            //         }
            //     });
            // }
        // });
    };

    //发送数据
    handleOk = () => {
        let data = this.form.current.getFieldsValue();
        let {equipParam} = this.state;
        console.log(data);
        // console.log(equipParam);

        let message = form.START_FORM + form.EQUIPMENT_FORM;
        Object.keys(data).forEach(function (key) {
            console.log(key, data[key]);
            //TODO:格式化
            if (key !== "areaName") {
                // console.log(data[key].replace(/./g, ""));
                message += data[key];
            }
        });
        message += form.END_FORM;

        axios.PostAjax({
            url: PORT_SEND_MESSAGE,
            data: {
                params: {message, port: equipParam.areaPort, unit: equipParam.equiUnit}
            }
        }).then((res) => {
            message.success(res.DATA);
            this.setState({visible: false, equipParam: {}});
            this.form.current.resetFields();//清除表单
            this.resetList();
        });
    };

    onCancel = () => {
        if (this.form.current) this.form.current.resetFields();
        this.setState({visible: false})
    };

    reset = () => {
        if (this.form.current) this.form.current.resetFields();
    };

    render() {
        const formItemLayout = {labelCol: {span: 6}, wrapperCol: {span: 16}};

        const {data, status, visible, value, equipParam, type, isClear} = this.state;
        let disabled = type === "view";
        // console.log(value);

        let head = <div style={{
            position: "relative",
            float: "right",
            marginBottom: "20px",
            display: this.props.userType === 2 ? "none" : "block"//只对管理员显示
        }}>
            {/*<BaseTool type="headButton" name="删除数据" onClick={() => this.handleOperation("deleteAll")}/>*/}
            <BaseTool type="headButton" name="发送数据" onClick={() => this.handleOperation("create")}/>
        </div>;

        const columns = [
            {
                title: '序号',
                dataIndex: 'key'
            }, {
                title: '区域',
                dataIndex: 'areaName'
            }, {
                title: '单元',
                dataIndex: 'equiUnit'
            }, {
                title: '设备编号',
                dataIndex: 'equiId',
            }, {
                title: '设备名称',
                dataIndex: 'equiName',
            }, {
                title: '通电时间',
                dataIndex: 'powerOnTime',
            }, {
                title: '断电时间',
                dataIndex: 'powerOffTime',
            }, {
                title: '通电电流',
                dataIndex: 'electricValue',
            }, {
                title: '发送时间',
                dataIndex: 'operationTime',
                render: text => {
                    return Utils.formatDate(text);
                }
            }, {
                title: '操作',
                dataIndex: 'operation',
                render: (text, record) => {
                    return (
                        <div>
                            <BaseTool type="button" name="查看详情" onClick={() => this.handleOperation("view", record)}/>
                            {/*<BaseTool type="button" name="删除" onClick={() => this.handleOperation("delete", record)}/>*/}
                        </div>
                    )
                }
            }];

        return (
            <div className="equipment">
                <BasePage formList={formList} head={head} columns={columns}
                          dataSource={this.state.dataSource} isClear={isClear}
                          updateClearFlag={() => this.setState({isClear: false})}
                          getList={(res) => this.resetList(res)}
                          pagination={this.state.pagination}
                          updateSelectedItem={(selectedRowKeys, record, selectedIds) => {
                              // console.log(selectedRowKeys, record, selectedIds);
                              this.setState({selectedRowKeys, record, selectedIds})
                          }}/>
                <BaseModal
                    width={600}
                    title={type === "create" ? "发送数据" : "查看数据"}
                    visible={visible}
                    onCancel={this.onCancel}
                    footer={null}
                    content={
                        <Form ref={this.form} onFinish={() => this.portState()} scrollToFirstError
                              initialValues={{state: 2}}
                              fields={
                                  [
                                      {"name": "areaName", "value": equipParam.areaName || ""},
                                      {"name": "equiUnit", "value": equipParam.equiUnit || ""},
                                      {"name": "equiName", "value": equipParam.equiName || ''},
                                      {"name": "powerOnTime", "value": equipParam.powerOnTime || ''},
                                      {"name": "powerOffTime", "value": equipParam.powerOffTime || ''},
                                      {"name": "electricValue", "value": equipParam.electricValue || ''},
                                  ]
                              }>
                            <FormItem label="区域" {...formItemLayout} name='areaName' rules={[{required: true}]}>
                                <Input disabled={disabled} onClick={() => this.setState({areaVisible: true})}/>
                            </FormItem>
                            <FormItem label="单元" {...formItemLayout} name='equiUnit' rules={[{required: true}]}>
                                <Input disabled={disabled} onClick={() => {
                                    if (equipParam.areaId) {
                                        this.setState({unitVisible: true});
                                    } else {
                                        message.error("请先选择区域");
                                    }
                                }}/>
                            </FormItem>
                            {type === "create" ?
                                <FormItem label="状态"  {...formItemLayout} name='state'
                                          rules={[{required: true}]}>
                                    <Radio.Group onChange={this.onChange} value={value} disabled={disabled}>
                                        <Radio value={2}>全部修改</Radio>
                                        <Radio value={1}>单点修改</Radio>
                                    </Radio.Group>
                                </FormItem> : null}
                            {status || type === "view" ?
                                <FormItem label="设备名称"  {...formItemLayout} name='equiName'
                                          rules={[{required: true}]}>
                                    <Input disabled={disabled} onClick={() => {
                                        // console.log(equipParam);
                                        if (equipParam.areaId && equipParam.equiUnit) {
                                            this.setState({equipVisible: true});
                                        } else if (!equipParam.areaId) {
                                            message.error("请先选择区域");
                                        } else if (!equipParam.equiUnit) {
                                            message.error("请先选择单元");
                                        }
                                    }}/>
                                </FormItem> : <div/>
                            }
                            <FormItem label="通电时间"  {...formItemLayout} name='powerOnTime'
                                      rules={[{required: true}, {
                                          pattern: /^[0-9]+$/,
                                          message: '通电时间格式错误！'
                                      }]}>
                                <Input disabled={disabled}/>
                            </FormItem>
                            <FormItem label="断电时间"  {...formItemLayout} name='powerOffTime'
                                      rules={[{required: true}, {
                                          pattern: /^[0-9]+$/,
                                          message: '断电时间格式错误！'
                                      }]}>
                                <Input disabled={disabled}/>
                            </FormItem>
                            <FormItem label="通电电流"  {...formItemLayout} name='electricValue'
                                      rules={[{required: true}, {
                                          pattern: /^[0-9]+$/,
                                          message: '通电电流格式错误！'
                                      }]}>
                                <Input disabled={disabled}/>
                            </FormItem>
                            <Form.Item>
                                {type === "view" ?
                                    <Row>
                                        <Col span={24} style={{textAlign: 'center'}}>
                                            <Button type="primary" onClick={this.onCancel}>
                                                确定
                                            </Button>
                                        </Col>
                                    </Row> :
                                    <Row>
                                        <Col span={12} style={{textAlign: 'right'}}>
                                            <Button type="primary" onClick={this.reset}>
                                                重置
                                            </Button>
                                        </Col>
                                        <Col span={12} style={{textAlign: 'left', paddingLeft: 30}}>
                                            <Button type="primary" htmlType="submit">
                                                提交
                                            </Button>
                                        </Col>
                                    </Row>
                                }
                            </Form.Item>
                        </Form>
                    }/>
                <BaseModal
                    width={1200}
                    title="选择区域"
                    visible={this.state.areaVisible}
                    onCancel={() => this.setState({areaVisible: false})}
                    footer={null}
                    content={<AreaManage propsType="select" handleClick={(res) => {
                        // console.log(res);
                        this.setState({
                            equipParam: {...equipParam, areaId: res.areaId, areaName: res.areaName, areaPort: res.areaPort},
                            areaVisible: false
                        })
                    }}/>}/>
                <BaseModal
                    width={1000}
                    title={"选择单元"}
                    visible={this.state.unitVisible}
                    onCancel={() => this.setState({unitVisible: false})}
                    footer={null}
                    content={<UnitManage propsType="select" unitParam={equipParam} handleClick={(res) => {
                        // console.log(res);
                        this.setState({
                            equipParam: {...equipParam, equiUnit: res.equiUnit, unitName: res.unitName},
                            unitVisible: false
                        })
                    }}/>}/>
                <BaseModal
                    width={1200}
                    title="选择设备"
                    visible={this.state.equipVisible}
                    onCancel={() => {
                        this.setState({equipVisible: false})
                    }}
                    footer={null}
                    content={<EquipmentManage
                        propsType="select" equipParam={equipParam}
                        handleClick={(res) => {
                            console.log(res);
                            this.setState({
                                equipParam: {...equipParam, equiId: res.equiId, equiName: res.equiName},
                                equipVisible: false
                            })
                        }}/>}/>
            </div>
        );
    }
}
import React from 'react';
import { Select, TreeSelect } from 'antd'

export const pageSize = 10;
export const pageSizeSmall = 5;

const Option = Select.Option;
let list = [];
let acls = {};
let checkChildren = false;
const { TreeNode } = TreeSelect;

function judge10(number) {
    if (number >= 10) {
        return number;
    } else {
        return "0" + number;
    }
}

export default {
    getDate() {
        return new Date();
    },
    getformatDate() {
        let date = new Date();
        return date.getFullYear() + '-' + judge10(date.getMonth() + 1) + '-' + judge10(date.getDate())
            + ' ' + judge10(date.getHours()) + ':' + judge10(date.getMinutes()) + ':' + judge10(date.getSeconds());
    },
    formatDate(time) {
        if (!time) return '';
        let date = new Date(time);
        return date.getFullYear() + '-' + judge10(date.getMonth() + 1) + '-' + judge10(date.getDate())
            + ' ' + judge10(date.getHours()) + ':' + judge10(date.getMinutes()) + ':' + judge10(date.getSeconds());
    },
    formatDateNoTime(time) {
        if (!time) return '';
        let date = new Date(time);
        return date.getFullYear() + '-' + judge10((date.getMonth() + 1)) + '-' + judge10(date.getDate());
    },
    pagination(res, callback) {
        // console.log(res);
        return {
            onChange: (current) => {
                callback(current)
            },

            current: res.current,//当前页数
            pageSize: res.size,//每页条数
            total: res.total,//数据总数
            showTotal: () => {//用于显示数据总量和当前数据顺序
                return `共${res.total}条`
            },

            showQuickJumper: true//是否可以快速跳转至某页
        }
    },
    getOptionList(data) {
        if (!data) {
            return [];
        }
        let options = [] //[<Option value="0" key="all_key">全部</Option>];
        data.map((item) => {
            options.push(<Option value={item.id} key={item.id}>{item.name}</Option>)
        })
        return options;
    },
    /**
     * ETable 行点击通用函数
     * @param {*选中行的索引} selectedRowKeys
     * @param {*选中行对象} selectedItem
     */
    updateSelectedItem(selectedRowKeys, selectedRows, selectedIds) {
        // console.log(selectedRowKeys)
        // console.log(selectedRows)
        // console.log(selectedIds)
        if (selectedIds) {
            this.setState({
                selectedRowKeys,
                selectedIds: selectedIds,
                selectedItem: selectedRows
            })
        } else {
            this.setState({
                selectedRowKeys,
                selectedItem: selectedRows
            })
        }
    },
    updateSelectedItem1(selectedRowKeys1, selectedRows1, selectedIds1) {
        if (selectedIds1) {
            this.setState({
                selectedRowKeys1,
                selectedIds1: selectedIds1,
                selectedItem1: selectedRows1
            })
        } else {
            this.setState({
                selectedRowKeys1,
                selectedItem1: selectedRows1
            })
        }
    },
    getDataSource(list) {
        let arr = JSON.parse(JSON.stringify(list));
        return this.IterationDelateMenuChildren(arr);
    },
    IterationDelateMenuChildren(arr) {
        if (arr.length) {
            for (let i in arr) {
                if (arr[i].childrenList.length) {
                    this.IterationDelateMenuChildren(arr[i].childrenList)
                } else {
                    delete arr[i].childrenList;
                }
            }
        }
        return arr
    },
    getTreeKey(data) {
        list = [];
        this.getTreeKeyChildren(data);
        return list;
    },
    getTreeKeyChildren(arr) {
        arr.forEach((item) => {
            if (item.childrenList) {
                this.getTreeKeyChildren(item.childrenList)
            } else {
                item.aclList.forEach((item) => {
                    if (item.checked == true) {
                        list.push(item.id.toString())
                    }
                })
            }
        })
    },
    getListKey(data) {
        list = [];
        data.forEach((item) => {
            list.push(item.id.toString())
        })
        return list;
    },
    getMenuList(data) {
        let menuList = [];
        acls = {};
        return { aclTree: this.getTreeMenu(data, menuList), acls: acls };
    },
    getTreeMenu(arr, listChildren) {
        listChildren = [];
        arr.forEach((item) => {
            if (this.checkMenuEmptyChildren(item.childrenList)) {
                listChildren.push({});
                listChildren.push({
                    title: item.name,
                    key: item.name,
                    childrenList: this.getTreeMenu(item.childrenList, listChildren.pop().childrenList)
                });
            } else if (item.aclList.filter((item) => item.type === 1).length > 0) {
                let key = item.aclList.filter((item) => item.type === 1)[0].url;
                listChildren.push({ title: item.name, key: key })
                acls[key] = item.aclList.filter((item) => item.type === 2).map((item) => item.url);
            }
        })
        return listChildren;
    },
    checkMenuEmptyChildren(arr) {
        checkChildren = false;
        this.handleMenuChildren(arr);
        return checkChildren;
    },
    handleMenuChildren(arr) {
        if (arr.length) {
            for (let i in arr) {
                if (arr[i].childrenList.length) {
                    this.handleMenuChildren(arr[i].childrenList)
                } else if (arr[i].aclList.filter((item) => item.type === 1).length > 0) {
                    checkChildren = true;
                }
            }
        }
    },
    renderTreeNodes(data) {
        return data.map((item) => {
            if (item.childrenList) {
                return (
                    <TreeNode title={item.name} value={item.id} key={item.id}>
                        {this.renderTreeNodes(item.childrenList)}
                    </TreeNode>
                );
            } else {
                return (
                    <TreeNode title={item.name} value={item.id} key={item.id} isLeaf />
                );
            }
        });
    },
    portStatus(data) {
        if (data.startsWith("0")) {
            return "未启动";
        } else if (data.startsWith("1")) {
            if (data.length > 3) {
                return "连接成功";
            } else {
                return "启动未连接";
            }
        }
        return "";
    }, portStatus1(data) {
        if (data === undefined || data === '') { return []; }
        else if (data.startsWith("1") && data.length > 3) {
            let portStr = data.substring(2, data.length - 1);
            let port = portStr.split(',');
            return port.map(item => {
                return item.replace(" ", "");
            });
        }
        return [];
    }, portStatus2(data) {
        if (data === undefined || data === '') { return []; }
        else if (data.startsWith("1") && data.length > 3) {
            let portStr = data.substring(2, data.length - 1);
            let port = portStr.split(',');
            return port.map(item => {
                return item + '；';
            });
        }
        return [];
    }
}
import React, {Component} from 'react';
import {Modal} from "antd";

export default class BaseModal extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }


    render() {
        const {width, title, visible, onOk, onCancel, content, footer} = this.props;

        return (
            <Modal
                width={width}
                title={title}
                visible={visible}
                footer={footer}
                onOk={onOk}
                okText={"确定"}
                cancelText={'取消'}
                destroyOnClose={true}//关闭后销毁
                maskClosable={false}//点击蒙层是否允许关闭
                keyboard={false}//是否支持键盘 esc 关闭
                onCancel={onCancel}>
                {content}
            </Modal>
        );
    }
}